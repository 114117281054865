import React, { FC } from "react";
import { GUESS_OUTCOME_TO_CLASSNAME_MAPPING } from "./GameBoard";
import './PreviousBoards.css';
import {HistoricalGuess} from "./types/GameState";

type PreviousBoardsProps = {
  guessHistory: Array<HistoricalGuess>
}

const PreviousBoards: FC<PreviousBoardsProps> = ({ guessHistory }) => {
  return (
    <div className="previousBoardsContainer">
      {
        guessHistory.slice(0,4).map((historicalGuess, i) => { // TO DO: ensure only 4 previous guesses are allowed
          const { guessGrid, guessOutcomeGrid } = historicalGuess;

          return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '45%', height: '50%'}} key={i}>
              <div className="boardContainer">
                {
                  guessGrid.map((row, r) => {
                    return (
                      <div className="previousBoardRow" key={`row-${r}`}>
                        {
                          row.map((cell, c) => {
                            const cellGuessOutcome = guessOutcomeGrid[r][c]
                            const cellClassName = GUESS_OUTCOME_TO_CLASSNAME_MAPPING[cellGuessOutcome]

                            return <div className={`previousBoardCell ${cellClassName}`} key={`cell-${c}`}>{cell}</div>
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default PreviousBoards;