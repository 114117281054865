import {Grid} from "../types/Grid";

export function fillGridRowWithWord(grid: Grid, rowIndex: number, word: string): void {
  for (let i = 0; i < word.length; i++) {
    grid[rowIndex][i] = word[i]
  }
}

export function fillGridColumnWithWord(grid: Grid, columnIndex: number, word: string): void {
  for (let i = 0; i < word.length; i++) {
    grid[i][columnIndex] = word[i]
  }
}

export function addLetterToGrid(grid: Grid, letter: string | null, rowIndex: number, columnIndex: number): void {
  grid[rowIndex][columnIndex] = letter;
}

export function addLetterToGridAndReturnNew(grid: Grid, letter: string | null, rowIndex: number, columnIndex: number): Grid {
  const newGrid = JSON.parse(JSON.stringify(grid))
  newGrid[rowIndex][columnIndex] = letter;
  return newGrid
}

export function removeLetterFromGrid(grid: Grid, rowIndex: number, columnIndex: number): void {
  grid[rowIndex][columnIndex] = null;
}

export function getRowWordByIndex(grid: Grid, rowIndex: number): Array<string | null> {
  return grid[rowIndex];
}

export function getColumnWordByIndex(grid: Grid, columnIndex: number): Array<string | null> {
  const columnWord = []
  for (let r = 0; r < grid.length; r++) {
    const row = grid[r];
    columnWord.push(row[columnIndex])
  }

  return columnWord
}