import { PUZZLES } from "../constants/gameWords";
import { Puzzle } from "../types/Puzzle";

const START_OF_2023_04_01 = 1680321601000;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export default function getPuzzle(): Puzzle {
  const currentTimeMilliseconds = Date.now();

  const millisecondsElapsed = currentTimeMilliseconds - START_OF_2023_04_01;
  const daysElapsed = millisecondsElapsed / DAY_IN_MILLISECONDS;

  const puzzleIndex = Math.floor(daysElapsed);

  return PUZZLES[puzzleIndex];
}
