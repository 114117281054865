import React, {FC} from 'react';
import "./Keyboard.css";
import {GUESS_OUTCOME_TO_CLASSNAME_MAPPING} from "./GameBoard";
import {LetterOutcomeHash} from "./types/GameState";

export const LEFT_ARROW_CODE = 37;
export const UP_ARROW_CODE = 38;
export const RIGHT_ARROW_CODE = 39;
export const DOWN_ARROW_CODE = 40

export const BACKSPACE_KEY_CODE = 8;
export const BACKSPACE_SYMBOL = '\u232b';

export const RETURN_KEY_CODE = 13;
export const RETURN_SYMBOL = '\u23ce';

const REMAINING_LETTERS = [
  'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
  'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',
  RETURN_SYMBOL, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', BACKSPACE_SYMBOL
];
const remainingLettersGrid = [
  REMAINING_LETTERS.slice(0,10),
  REMAINING_LETTERS.slice(10,19),
  REMAINING_LETTERS.slice(19,28),
]

type Props = {
  remainingLettersOutcomes: LetterOutcomeHash;
  addGuessLetter: (letter: string | null) => void;
}

const Keyboard: FC<Props> = ({
   remainingLettersOutcomes,
   addGuessLetter
}) => {
  return <div className="remainingLettersContainer">
    {
      remainingLettersGrid.map((row, r) => {
        const rowClass = r === 0 ? 'firstRow' : (r === 1 ? 'secondRow' : 'thirdRow')
        const cellClass = r === 0 ? 'firstRowCell' : (r === 1 ? 'secondRowCell' : 'thirdRowCell')

        return (<div className={`keyboardRow ${rowClass}`} key={r}>
          {
            row.map((letter, c) => {
              return (
                <CellDisplay
                  key={c}
                  letter={letter}
                  widthClass={cellClass}
                  onClick={() => {
                    addGuessLetter(letter === BACKSPACE_SYMBOL ? null : letter);
                  }}
                  remainingLettersOutcomes={remainingLettersOutcomes}
                />
              )
            })
          }
        </div>)
      })
    }
  </div>
}

export default Keyboard


type CellDisplayProps = {
  letter: string;
  onClick: () => void;
  remainingLettersOutcomes: LetterOutcomeHash;
  widthClass: string;
}

const CellDisplay: FC<CellDisplayProps> = ({ letter, onClick, remainingLettersOutcomes, widthClass }) => {
  const colorClass = letter ? GUESS_OUTCOME_TO_CLASSNAME_MAPPING[remainingLettersOutcomes[letter]] : '';
  const specialCharacterClass = [BACKSPACE_SYMBOL, RETURN_SYMBOL].includes(letter) ? 'wideCharacter' : ''
  return (
    <div className={`remainingLetterCell ${letter ? '' : 'gone'} ${colorClass} ${widthClass} ${specialCharacterClass}`} onClick={onClick}>{letter}</div>
  )
}