import React, {useEffect, useState} from 'react';
import './Game.css';
import GameBoard from "./GameBoard";
import PreviousBoards from "./PreviousBoards";
import Keyboard, {BACKSPACE_KEY_CODE, RETURN_KEY_CODE, RETURN_SYMBOL} from "./Keyboard";
import { Grid } from "./types/Grid";
import {
  addLetterToGridAndReturnNew,
} from "./utils/grid";
import Cell from "./types/Cell";
import {
  didWin,
  getGuessErrors,
  getGuessOutcomeGrid,
  getResetGuessGridWithCorrectGuessesOnly,
  getUpdatedRemainingLettersOutcomes,
} from "./helpers/evaluateGuess";
import {LetterOutcome} from "./types/WordGuessOutcome";
import toast, { Toaster } from 'react-hot-toast';
import {GameOutcome, GameState, GuessOutcomeGrid, HistoricalGuess, LetterOutcomeHash} from "./types/GameState";
import getNextFocusedCell from "./helpers/getNextFocusedCell";
import getNextFocusedCellFromArrowKeyCode from "./helpers/getNextFocusedCellFromArrowKeyCode";
import GameEndOverlay from "./GameEndOverlay";

const MAX_GUESSES = 5;

type GameProps = {
  wordQuartet: Array<string>;
  gameState: GameState;
  saveGameState: (gameState: GameState) => void;
}

type PressedKey = {
  key: string | null;
}

type PressedCode = {
  code: number | null;
}

function Game({ wordQuartet, gameState, saveGameState }: GameProps) {
  const [answerGrid] = useState<Grid>(gameState.answerGrid)
  const [guessGrid, setGuessGrid] = useState<Grid>(gameState.guessGrid)
  const [focusedCell, setFocusedCell] = useState<Cell | null>(gameState.focusedCell)
  const [prevFocusedCell, setPrevFocusedCell] = useState<Cell | null>(null)
  const [guessOutcomeGrid, setGuessOutcomeGrid] = useState<GuessOutcomeGrid>(gameState.guessOutcomeGrid);
  const [remainingLettersOutcomes, setRemainingLettersOutcomes] = useState<LetterOutcomeHash>(gameState.remainingLettersOutcomes);
  const [guessHistory, setGuessHistory] = useState<Array<HistoricalGuess>>(gameState.guessHistory);
  const [pressedKey, setPressedKey] = useState<PressedKey>({ key: null });
  const [pressedArrowCode, setPressedArrowCode] = useState<PressedCode>({ code: null });
  
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom()
    }, 500)
  }, []);

  function setPressedKeyFromKeyPressEvent(keyEvent: any): void {
    if ((keyEvent.keyCode >= 65 && keyEvent.keyCode <= 90)) {
      setPressedKey({key: keyEvent.key.toUpperCase()})
    } else if (keyEvent.keyCode === BACKSPACE_KEY_CODE) {
      setPressedKey({key: null});
    } else if (keyEvent.keyCode === RETURN_KEY_CODE) {
      setPressedKey({key: RETURN_SYMBOL});
    } else if (keyEvent.keyCode >= 37 && keyEvent.keyCode <= 40) {
      setPressedArrowCode({code: keyEvent.keyCode})
    }
  }

  function addGuessLetter(letter: string | null) {
    if (focusedCell) {
      if (letter === RETURN_SYMBOL) {
        handleGuess();
      } else {
        if (guessOutcomeGrid[focusedCell.row][focusedCell.column] === LetterOutcome.CORRECT) {
          // Do nothing when a locked-in green cell is focused (they are un-editable)
        } else {
          const updatedGuessGrid = addLetterToGridAndReturnNew(guessGrid, letter, focusedCell.row, focusedCell.column)
          setGuessGrid(updatedGuessGrid)
          if (letter) {
            const nextFocusedCell = getNextFocusedCell(focusedCell, prevFocusedCell, updatedGuessGrid);
            setPrevFocusedCell(focusedCell);
            setFocusedCell(nextFocusedCell);
          }
        }
      }
    }
  }

  // Key-press listener that sets the pressedKey state
  useEffect(() => {
    document.addEventListener('keydown', setPressedKeyFromKeyPressEvent);

    return () => document.removeEventListener('keydown', setPressedKeyFromKeyPressEvent);
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  // Add a guess letter whenever the pressedKey is triggered
  useEffect(() => {
    addGuessLetter(pressedKey.key)
  }, [pressedKey]) // eslint-disable-line react-hooks/exhaustive-deps

  // Adjust the focused cell whenever the pressedArrowCode is triggered
  useEffect(() => {
    if (pressedArrowCode.code) {
      const nextFocusedCell = getNextFocusedCellFromArrowKeyCode(focusedCell, pressedArrowCode.code)
      if (nextFocusedCell) {
        setPrevFocusedCell(focusedCell);
        setFocusedCell(nextFocusedCell);
      }
    }
  }, [pressedArrowCode]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleGuess(): void {
    const guessErrors = getGuessErrors(guessGrid, guessOutcomeGrid);
    if (guessErrors.length > 0) {
      toast.error(guessErrors[0], {
        duration: 3000,
      })
      return;
    }

    const newGuessOutcomeGrid = getGuessOutcomeGrid(guessGrid, answerGrid);

    if (didWin(newGuessOutcomeGrid)) {
      handleNextRound(guessGrid, newGuessOutcomeGrid, guessHistory, 'won')
    } else {
      if (guessHistory.length >= MAX_GUESSES - 1) {
        handleLostGame(guessGrid, newGuessOutcomeGrid);
      } else {
        handleNextRound(guessGrid, newGuessOutcomeGrid, guessHistory)
      }
    }
    setTimeout(() => {
      scrollToBottom()
    }, 500)
  }

  function handleLostGame(guessGrid: Grid, guessOutcomeGrid: GuessOutcomeGrid) {
    setGuessGrid(guessGrid);
    setGuessOutcomeGrid(guessOutcomeGrid);

    const updatedRemainingLettersOutcomes = getUpdatedRemainingLettersOutcomes(remainingLettersOutcomes, guessGrid, guessOutcomeGrid);
    saveGameState({
      wordQuartet,
      answerGrid,
      guessGrid,
      guessOutcomeGrid,
      guessHistory,
      focusedCell: null,
      remainingLettersOutcomes: updatedRemainingLettersOutcomes,
      outcome: 'lost',
    })
    setFocusedCell(null);
  }

  function handleNextRound(guessGrid: Grid, guessOutcomeGrid: GuessOutcomeGrid, guessHistory: Array<HistoricalGuess>, outcome: GameOutcome = 'pending') {
    const updatedGuessHistory = [
      ...guessHistory,
      {
        guessGrid: JSON.parse(JSON.stringify(guessGrid)),
        guessOutcomeGrid: JSON.parse(JSON.stringify(guessOutcomeGrid)),
      }
    ]
    setGuessHistory(updatedGuessHistory)

    const updatedGuessGrid = getResetGuessGridWithCorrectGuessesOnly(guessGrid, guessOutcomeGrid);
    setGuessGrid(updatedGuessGrid);

    const updatedGuessOutcomeGrid = getGuessOutcomeGrid(updatedGuessGrid, answerGrid);
    setGuessOutcomeGrid(updatedGuessOutcomeGrid);

    setFocusedCell(null);

    const updatedRemainingLettersOutcomes = getUpdatedRemainingLettersOutcomes(remainingLettersOutcomes, guessGrid, guessOutcomeGrid);
    setRemainingLettersOutcomes(updatedRemainingLettersOutcomes);

    saveGameState({
      wordQuartet,
      answerGrid,
      guessGrid: updatedGuessGrid,
      guessOutcomeGrid: updatedGuessOutcomeGrid,
      guessHistory: updatedGuessHistory,
      focusedCell: null,
      remainingLettersOutcomes: updatedRemainingLettersOutcomes,
      outcome: outcome,
    })
  }

  return (
    <div className="contentContainer">
      <GameEndOverlay outcome={gameState.outcome} wordQuartet={wordQuartet} />
      <div style={{ height: '47%', paddingTop: 7 }}>
        <PreviousBoards guessHistory={guessHistory} />
      </div>
      <div style={{ height: '35%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <GameBoard
            answerGrid={answerGrid}
            guessGrid={guessGrid}
            guessOutcomeGrid={guessOutcomeGrid}
            focusedCell={focusedCell}
            setFocusedCell={setFocusedCell}
          />
        </div>
      </div>
      {/*<div style={{ display: 'flex', justifyContent: 'center', height: '10%' }}>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      marginTop: 10,*/}
      {/*      marginBottom: 10,*/}
      {/*      border: 'solid 2px black',*/}
      {/*      borderRadius: 10,*/}
      {/*      padding: 10,*/}
      {/*      cursor: 'pointer',*/}
      {/*      backgroundColor: 'lightblue',*/}
      {/*    }}*/}
      {/*    onClick={handleGuess}*/}
      {/*  >*/}
      {/*    SUBMIT GUESS*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="keyboardContainer" style={{ height: '18%' }}>
        <Keyboard
          remainingLettersOutcomes={remainingLettersOutcomes}
          addGuessLetter={addGuessLetter}
        />
      </div>
      <Toaster />
    </div>
  );
}

export default Game;
