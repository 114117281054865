import React, {Dispatch, FC, SetStateAction} from 'react';
import './GameBoard.css';
import Cell from "./types/Cell";
import {Grid} from "./types/Grid";
import {LetterOutcome} from "./types/WordGuessOutcome";
import {GuessOutcomeGrid} from "./types/GameState";

type Props = {
  answerGrid: Grid;
  guessGrid: Grid;
  guessOutcomeGrid: GuessOutcomeGrid;
  focusedCell: Cell | null;
  setFocusedCell: Dispatch<SetStateAction<Cell | null>>;
}

export const GUESS_OUTCOME_TO_CLASSNAME_MAPPING = {
  [LetterOutcome.CORRECT]: 'green',
  [LetterOutcome.LETTER_IN_WORD]: 'yellow',
  [LetterOutcome.LETTER_ON_BOARD]: 'orange',
  [LetterOutcome.INCORRECT]: 'gray',
  [LetterOutcome.NO_GUESS]: 'open',
  [LetterOutcome.NULL]: 'hidden',
}

const GameBoard: FC<Props> = ({
  answerGrid,
  guessGrid,
  guessOutcomeGrid,
  focusedCell,
  setFocusedCell,
}) => {

  function handleCellClick(r: number, c: number) {
    if ([1,3].includes(r) || [1,3].includes(c)) {
      setFocusedCell({
        row: r,
        column: c,
      })
    }
  }

  const content = guessGrid.map((row, r) => {
    return (
      <div className="row" key={`row-${r}`}>
        {
          row.map((cell, c) => {
            const cellGuessOutcome = guessOutcomeGrid[r][c]
            const colorClass = GUESS_OUTCOME_TO_CLASSNAME_MAPPING[cellGuessOutcome]
            const focusClass = (focusedCell && r === focusedCell.row && c === focusedCell.column) ? 'focused' : ''

            return <div className={`cell ${colorClass} ${focusClass}`} key={`cell-${c}`} onClick={() => { handleCellClick(r,c) }}>{cell}</div>
          })
        }
      </div>
    )
  })

  return (
    <div className="gameBoardContainer">
      { content }
    </div>
  )
}

export default GameBoard;