import { Puzzle } from "../types/Puzzle";

// 365 days of word quartets
const WORD_QUARTETS: Array<Array<string>> = [
  ["SHARK", "GHOUL", "GUILT", "TRULY"],
  ["SONIC", "ROUGH", "AGILE", "WIELD"],
  ["STOLE", "STORY", "GRUEL", "SLEEK"],
  ["LEGAL", "BEEFY", "OFTEN", "CADET"],
  ["WIDER", "RIGHT", "CHART", "HEART"],
  ["ABHOR", "ABOVE", "EVOKE", "VODKA"],
  ["SILLY", "FINCH", "SCENT", "CLUNG"],
  ["DITCH", "DISCO", "SCORN", "SCARE"],
  ["RIVER", "VISIT", "HIPPY", "TEMPO"],
  ["ICILY", "SCION", "VOUCH", "BLACK"],
  ["GOURD", "NOTCH", "SCREW", "GREEN"],
  ["DAIRY", "SALTY", "STALL", "TRULY"],
  ["FOUND", "WOUND", "KNOWN", "SNOWY"],
  ["BOOST", "FORUM", "SULLY", "PSALM"],
  ["BROTH", "DROOL", "JOINT", "ATONE"],
  ["PUTTY", "GUMMY", "IMAGE", "STAGE"],
  ["CHUNK", "CHALK", "BLUER", "ANGEL"],
  ["VALOR", "VALVE", "EVICT", "POUCH"],
  ["PARKA", "GAMER", "WEARY", "SKIRT"],
  ["PHASE", "THING", "UNDER", "ISLET"],
  ["HYENA", "CYCLE", "GLINT", "INANE"],
  ["LUCID", "RURAL", "RARER", "LINER"],
  ["CLOUT", "BLOCK", "SCARY", "QUERY"],
  ["STALK", "STEEL", "PENAL", "BLEAT"],
  ["BROTH", "ORDER", "HEDGE", "STAGE"],
  ["ALPHA", "BLURT", "WREST", "GHOST"],
  ["REBUT", "LEDGE", "AGENT", "SUNNY"],
  ["WEAVE", "TEETH", "STUNT", "EVENT"],
  ["RIGOR", "PIPER", "TERSE", "WORST"],
  ["BRINY", "BRICK", "SCANT", "INANE"],
  ["TAUNT", "TALON", "ROGUE", "UNDUE"],
  ["TOTEM", "COMIC", "DIRTY", "TENTH"],
  ["TOWEL", "FORUM", "PULSE", "GEESE"],
  ["TRUSS", "GRUFF", "AFIRE", "USURP"],
  ["MOLDY", "BORNE", "INLET", "UDDER"],
  ["BLUER", "PLAZA", "OZONE", "PENNY"],
  ["HITCH", "HIPPO", "APART", "ACORN"],
  ["SWORE", "SWELL", "BLOWN", "PROWL"],
  ["LOBBY", "WOULD", "SLUNG", "EBONY"],
  ["QUALM", "QUITE", "STAMP", "FLUME"],
  ["MANGO", "TANGY", "AGONY", "AGING"],
  ["VAUNT", "LAPEL", "DETER", "UNSET"],
  ["EXERT", "EXTRA", "FRANK", "TREND"],
  ["REBEL", "GENRE", "GRAPH", "TEMPO"],
  ["GREAT", "BRAND", "ANGER", "LAPEL"],
  ["DAILY", "LATCH", "SCARY", "ALERT"],
  ["BREED", "TRICK", "ACUTE", "DEATH"],
  ["REVEL", "DEMON", "HOTEL", "LEVER"],
  ["PUPPY", "RUDDY", "ADULT", "APPLE"],
  ["EXTRA", "EXPEL", "FETAL", "TRIAD"],
  ["HOVEL", "MODEL", "PEACE", "BEACH"],
  ["SEWER", "DENIM", "FIFTH", "MEATY"],
  ["NIGHT", "LIGHT", "THEFT", "SHIFT"],
  ["CATER", "TAMER", "REEDY", "READY"],
  ["BOOTH", "DONOR", "HONEY", "OTTER"],
  ["BESET", "LEVEL", "GECKO", "LEAKY"],
  ["OCTAL", "SCENT", "SNIDE", "TARDY"],
  ["ASSAY", "USUAL", "HABIT", "VALID"],
  ["HASTY", "PATIO", "LIVID", "STAIR"],
  ["FANCY", "PATTY", "STRUT", "SCRUM"],
  ["FINCH", "KINKY", "SKIRT", "SCORE"],
  ["LEVEL", "TENET", "BESET", "REFER"],
  ["MODEM", "VOTER", "HEARD", "RETRY"],
  ["EERIE", "BEGIN", "FIFTH", "VISTA"],
  ["SHINY", "SHUNT", "INGOT", "INBOX"],
  ["APRON", "SPEAR", "PASTA", "LOFTY"],
  ["GLOSS", "SLOPE", "SPARE", "USURP"],
  ["THINK", "THIRD", "FRUIT", "SNAIL"],
  ["CAULK", "RAINY", "KNOLL", "SLYLY"],
  ["PUPPY", "PUSHY", "SHOWY", "SPAWN"],
  ["GIRLY", "FILET", "TEACH", "FLECK"],
  ["CANOE", "SALVE", "AVIAN", "MOLAR"],
  ["SURGE", "GUIDE", "UDDER", "AGREE"],
  ["LEMON", "BENCH", "SCALE", "NOBLE"],
  ["CLUED", "OLIVE", "EVENT", "BEING"],
  ["CHOKE", "CHARM", "GRANT", "SKUNK"],
  ["TWICE", "SWILL", "FLAIL", "ACRID"],
  ["IRATE", "PRESS", "ESSAY", "STRAW"],
  ["BLURB", "BLEAT", "FAUNA", "BRAND"],
  ["FROTH", "CRANE", "INTER", "STEER"],
  ["SHARD", "CHAIR", "LIMIT", "GRAIN"],
  ["PASTE", "PARSE", "ASKEW", "OTHER"],
  ["ROUND", "COURT", "GRUEL", "ENTER"],
  ["DRUNK", "WRECK", "SCARF", "SNARL"],
  ["ELDER", "ALONE", "UNFED", "REFER"],
  ["ADAPT", "ADAGE", "IGLOO", "APRON"],
  ["JUMPY", "QUACK", "SCONE", "SPANK"],
  ["CAULK", "CAROL", "MONTH", "CLOTH"],
  ["VIGOR", "PICKY", "SKIRT", "GOURD"],
  ["FLAME", "PLUME", "SMILE", "AMBLE"],
  ["FILMY", "FINAL", "TASTE", "SMITE"],
  ["ELEGY", "FLUFF", "AFTER", "EGRET"],
  ["CRASH", "CROWD", "OWNER", "ASKEW"],
  ["RENAL", "DEMON", "CONIC", "RAPID"],
  ["PRIED", "GRACE", "SCION", "HERON"],
  ["PHONE", "WHIRL", "WRYLY", "ANKLE"],
  ["PLANT", "GLAND", "SNACK", "KNOCK"],
  ["COMIC", "SOBER", "DEPTH", "FIFTY"],
  ["ENEMY", "SNEAK", "TAKEN", "EMBED"],
  ["SAVVY", "SAUCE", "OCEAN", "AVIAN"],
  ["THANK", "PHONY", "ENACT", "KNACK"],
  ["BADLY", "RAPID", "VIXEN", "SLEEP"],
  ["SPIRE", "SPICY", "SCALD", "DRYLY"],
  ["THANK", "SHALE", "FLAKE", "SNAKE"],
  ["STALL", "STOOP", "BOARD", "BLARE"],
  ["BURLY", "BUNCH", "SCOOP", "GLOOM"],
  ["SPOON", "APING", "ONION", "COLOR"],
  ["ANGEL", "UNTIL", "PIPER", "METER"],
  ["SAFER", "CANAL", "CAMEO", "BESET"],
  ["AVAIL", "EVENT", "UNITY", "MIRTH"],
  ["SPIED", "SPRIG", "GIANT", "PENNY"],
  ["CHASM", "CHASE", "USHER", "ISLET"],
  ["SNOWY", "UNIFY", "AFFIX", "AWAIT"],
  ["RIPER", "BIBLE", "CLUED", "REPEL"],
  ["LEMON", "FETAL", "GAWKY", "VODKA"],
  ["FREAK", "FROZE", "AZURE", "PARRY"],
  ["THING", "CHASM", "USUAL", "INLAY"],
  ["QUACK", "LUCKY", "SKIER", "SCREW"],
  ["CATTY", "RAPID", "BISON", "STOOP"],
  ["CANNY", "TARDY", "ADOPT", "INEPT"],
  ["VEGAN", "JEWEL", "RESET", "LAPEL"],
  ["BROOD", "BROOM", "TOXIC", "CONIC"],
  ["STOVE", "STEAD", "MARCH", "EVICT"],
  ["ABATE", "ABOUT", "QUEER", "ETHER"],
  ["ABATE", "ABUSE", "ISSUE", "STRUT"],
  ["PASTY", "FATAL", "HASTY", "STATE"],
  ["STATE", "STILT", "FLOUT", "STOUT"],
  ["SPERM", "OPERA", "TRACE", "PRICE"],
  ["SHEER", "CHUTE", "STOOP", "DEPOT"],
  ["SOGGY", "SONAR", "BADLY", "AGILE"],
  ["FETID", "MERCY", "SCOLD", "DIMLY"],
  ["UNSET", "GNOME", "SMIRK", "PEARL"],
  ["BLAME", "PLANK", "UNCUT", "IMBUE"],
  ["SAPPY", "BARON", "TODAY", "SPRAY"],
  ["SHAPE", "THUMB", "EMAIL", "SPLIT"],
  ["LOWER", "POUND", "INLET", "SEVER"],
  ["GLOAT", "FLUID", "LINEN", "WATER"],
  ["SHOUT", "THROW", "NOISE", "GUISE"],
  ["SLINK", "ALGAE", "PALER", "INTER"],
  ["ELATE", "GLOOM", "LOWER", "STEER"],
  ["ANGEL", "UNZIP", "GIVEN", "TENET"],
  ["GAWKY", "CANNY", "INLET", "SKIER"],
  ["ELFIN", "CLOUD", "DUVET", "PIXEL"],
  ["PUSHY", "CURVE", "EVERY", "SHIRE"],
  ["MEDIA", "VERSE", "ASSET", "FIBER"],
  ["ZEBRA", "SERUM", "SUGAR", "FRIAR"],
  ["CAMEL", "CABIN", "WINCH", "PERCH"],
  ["TUTOR", "QUITE", "STAGE", "TOUGH"],
  ["ETHOS", "ETHER", "FEWER", "POKER"],
  ["BALMY", "WASTE", "UTTER", "EMBED"],
  ["PUPIL", "SUMAC", "DALLY", "HILLY"],
  ["RUSTY", "DUSTY", "STEED", "STEER"],
  ["WIDEN", "DIARY", "DRYLY", "BELLY"],
  ["WIMPY", "VIXEN", "FETUS", "SPOUT"],
  ["UNFED", "INCUR", "LUMPY", "TEMPO"],
  ["FLUSH", "BLOND", "ANNUL", "ISSUE"],
  ["SLACK", "SLEEK", "VEGAN", "OCTAL"],
  ["SERIF", "HELLO", "ALLOW", "WIDOW"],
  ["BRAIN", "PRIVY", "AVIAN", "TIDAL"],
  ["FLORA", "SLURP", "DRANK", "PRONE"],
  ["SCANT", "ACUTE", "STRAP", "INLAY"],
  ["POPPY", "SOAPY", "APING", "SPENT"],
  ["BLEAK", "CLOWN", "SWINE", "DAUNT"],
  ["FLEET", "BLOAT", "PANIC", "BEGIN"],
  ["DONOR", "SONIC", "BIGOT", "MOTOR"],
  ["FLUNK", "BLAST", "USHER", "UNDER"],
  ["MOURN", "GOOFY", "OFFER", "FREER"],
  ["ARBOR", "CRICK", "SCENT", "BOUND"],
  ["EXALT", "EXCEL", "LEVER", "BLEED"],
  ["SHYLY", "THEFT", "OFTEN", "SLEEP"],
  ["BRAWL", "GRUEL", "BEARD", "SWORN"],
  ["MUNCH", "QUICK", "SCORN", "SCORE"],
  ["EVOKE", "OVARY", "IRATE", "SKATE"],
  ["QUOTE", "CUMIN", "FINAL", "STRAP"],
  ["CHAIN", "CHUNK", "ANGLE", "FIELD"],
  ["INDEX", "ANGLE", "SLASH", "HEIST"],
  ["DECRY", "RECUR", "DUMPY", "GRAPE"],
  ["CLEAR", "FLOUT", "OUTDO", "PADDY"],
  ["LAUGH", "PASTA", "STONY", "AGENT"],
  ["PLAIT", "PLUCK", "ACTOR", "VIGOR"],
  ["BLINK", "BLUSH", "ASSAY", "KNEAD"],
  ["COVET", "WORST", "USAGE", "WEDGE"],
  ["INERT", "UNDER", "MECCA", "BRICK"],
  ["VIGOR", "LINEN", "DECOY", "COLOR"],
  ["TREAD", "GRIPE", "APTLY", "EAGLE"],
  ["EMBER", "AMAZE", "OZONE", "BEING"],
  ["SOGGY", "IONIC", "LINEN", "AGREE"],
  ["GONER", "MOUSE", "USURP", "DECRY"],
  ["DEMUR", "LEAVE", "EVENT", "SUING"],
  ["POISE", "POWER", "REEDY", "ASIDE"],
  ["PUBIC", "RUDER", "TENET", "SINEW"],
  ["SHOWY", "CHEST", "PSALM", "SWILL"],
  ["CHANT", "PHASE", "ASHEN", "UNDER"],
  ["WENCH", "HEIST", "ASSAY", "SCRAP"],
  ["GROPE", "BRICK", "SCONE", "SPUNK"],
  ["NEWLY", "VENOM", "DOZEN", "FLEET"],
  ["SAUCE", "SAFER", "RESIN", "ACRID"],
  ["MORAL", "HONOR", "FOLLY", "DAILY"],
  ["STALK", "ETHER", "DEPOT", "GLOOM"],
  ["PAGAN", "NAVAL", "MADAM", "BANAL"],
  ["DOWNY", "COWER", "RENEW", "ENTER"],
  ["GUEST", "SUPER", "HEARD", "USURP"],
  ["VOTER", "TOPIC", "HIPPO", "TEMPO"],
  ["BRIEF", "WRING", "UNMET", "BEVEL"],
  ["PINCH", "MIDGE", "AGILE", "SCALD"],
  ["PATCH", "CAPER", "JELLY", "SCALY"],
  ["BEGAN", "WEIRD", "TRIBE", "MAYBE"],
  ["GENRE", "DEFER", "GENIE", "ORBIT"],
  ["STARK", "ATTIC", "WITCH", "WRECK"],
  ["SCOUT", "SCARF", "ORGAN", "SUMAC"],
  ["PRANK", "DRAWN", "DWARF", "ANGRY"],
  ["QUELL", "LUNCH", "ACUTE", "BLITZ"],
  ["THESE", "GHOUL", "GUILE", "PSALM"],
  ["RESIN", "REFER", "RESET", "FIBER"],
  ["SYRUP", "CYCLE", "ALOUD", "QUEUE"],
  ["SCOUR", "OCTET", "MELEE", "LUMEN"],
  ["NOISE", "BOOBY", "ABODE", "ASIDE"],
  ["KNOCK", "KNOWN", "SWELL", "SCALY"],
  ["HATER", "CATER", "LEAVE", "HEAVE"],
  ["PAGAN", "WATER", "SEWER", "HATER"],
  ["VALET", "MANOR", "GOOEY", "SEVER"],
  ["GUAVA", "QUEEN", "MECCA", "EVICT"],
  ["GLEAN", "SLEET", "VENOM", "HAVOC"],
  ["RIVET", "FINER", "BEACH", "PERCH"],
  ["MOLDY", "NOVEL", "LEVEL", "ODDER"],
  ["QUEST", "MURKY", "SKUNK", "USING"],
  ["DREAD", "PRONG", "SNARL", "HAIRY"],
  ["MUSKY", "QUACK", "OCTET", "SKIER"],
  ["GENRE", "TENET", "DERBY", "PROBE"],
  ["MURAL", "RURAL", "SADLY", "TABLE"],
  ["CLASS", "BLOKE", "SKUNK", "USING"],
  ["SENSE", "PESTO", "STRAY", "ESSAY"],
  ["SNUFF", "SNAKY", "SKIRT", "AFIRE"],
  ["LUMEN", "PURER", "SETUP", "VENUE"],
  ["TOTEM", "RODEO", "PEACE", "TEACH"],
  ["DROOP", "TROPE", "SPICY", "VOICE"],
  ["RAINY", "SAUNA", "KNELT", "ANKLE"],
  ["ENJOY", "UNTIL", "VITAL", "SONAR"],
  ["PALER", "VALUE", "SURLY", "JELLY"],
  ["ADOBE", "ODDER", "ZESTY", "ABATE"],
  ["BERTH", "BEACH", "ACRID", "STAIN"],
  ["SHOCK", "THIGH", "AGING", "SCONE"],
  ["FLAKY", "GLAND", "GNOME", "SKIMP"],
  ["HORSE", "CONIC", "VITAL", "ESSAY"],
  ["NICHE", "LIVER", "REACH", "SHUCK"],
  ["SUAVE", "GUISE", "USUAL", "AVIAN"],
  ["EXCEL", "AXIOM", "TORCH", "FETCH"],
  ["GRIMY", "ARGUE", "DUVET", "IMPEL"],
  ["ARBOR", "PRESS", "ASIDE", "HORDE"],
  ["STOKE", "STATE", "STAMP", "SKIMP"],
  ["MEDIC", "FERAL", "EARTH", "FIFTY"],
  ["VOTER", "LOGIN", "BISON", "FELON"],
  ["IRATE", "TRIBE", "ABBEY", "STEED"],
  ["SQUAT", "EQUAL", "RACER", "LABEL"],
  ["ANGEL", "ANGRY", "BREED", "LEPER"],
  ["PROXY", "PRUDE", "ODDER", "EXPEL"],
  ["NEWLY", "HELIX", "TITLE", "SLYLY"],
  ["ALPHA", "BLACK", "SCRUB", "GHOUL"],
  ["COUCH", "TONIC", "MINER", "SCREW"],
  ["SEVEN", "PERIL", "VIPER", "NEWER"],
  ["CAGEY", "VAPID", "RIDER", "REVEL"],
  ["RENEW", "VENUE", "BUNNY", "PENNY"],
  ["HAREM", "PAINT", "ANGER", "MELEE"],
  ["RARER", "CAGEY", "DENIM", "RELIC"],
  ["SERIF", "BESET", "REBUT", "MINUS"],
  ["LEERY", "MECCA", "SCRAP", "ORGAN"],
  ["COUNT", "DODGE", "AGAIN", "UNLIT"],
  ["LOSER", "MODAL", "FALSE", "LEASH"],
  ["FORAY", "COMET", "PESTO", "PARTY"],
  ["SONIC", "BOTCH", "SCONE", "FIEND"],
  ["THORN", "CHALK", "BLAZE", "PRIZE"],
  ["WENCH", "JEWEL", "JELLY", "SCALY"],
  ["FLOWN", "FLANK", "UNITE", "SWATH"],
  ["ELUDE", "ALBUM", "HUNCH", "EDICT"],
  ["PIPER", "VIVID", "HIPPO", "TEMPO"],
  ["IMPLY", "AMPLE", "FLOSS", "CLASH"],
  ["STAMP", "STOMP", "AMPLY", "SMELL"],
  ["UNZIP", "INBOX", "POUND", "PIANO"],
  ["HOTLY", "FORGO", "AGONY", "BLEND"],
  ["CUMIN", "FUNNY", "UNZIP", "VIGIL"],
  ["GROUT", "PROWL", "SWOOP", "FUROR"],
  ["WATER", "MADAM", "GAUNT", "MEANT"],
  ["ARGUE", "FRONT", "INNER", "BUYER"],
  ["MERRY", "FENCE", "ICILY", "DRILL"],
  ["FRESH", "TRITE", "STEEP", "ASHEN"],
  ["SPAWN", "SPRAY", "SAUCY", "TWICE"],
  ["SMOCK", "SMEAR", "FACET", "OCTET"],
  ["HONEY", "LOFTY", "STYLE", "CELLO"],
  ["FEWER", "VENUE", "JUICE", "BELCH"],
  ["SNOOP", "ANGER", "DECRY", "SORRY"],
  ["COAST", "NOBLY", "BLOND", "USING"],
  ["STUNK", "STRAY", "WAVER", "ANGER"],
  ["WRIST", "BREED", "DECAL", "USUAL"],
  ["EARTH", "LAGER", "HEAVE", "STOVE"],
  ["GIRTH", "FISHY", "CHAFF", "STUFF"],
  ["STOLE", "ETHER", "HENCE", "BLOCK"],
  ["SONIC", "CORNY", "SNIDE", "DIODE"],
  ["FORAY", "MOIST", "ASSAY", "KAYAK"],
  ["DEMON", "FELON", "MOGUL", "BONUS"],
  ["HORNY", "WOUND", "UNDER", "INDEX"],
  ["WAGER", "RANDY", "ADAGE", "VERGE"],
  ["FEVER", "PEDAL", "EATEN", "SEVER"],
  ["RARER", "HASTY", "STALE", "NEWLY"],
  ["GLOOM", "PLANT", "GNOME", "DOGMA"],
  ["SHAPE", "THIGH", "AGING", "SPINY"],
  ["LEPER", "TESTY", "STEER", "SEWER"],
  ["FLICK", "ALLOT", "COBRA", "ACORN"],
  ["EGRET", "AGAPE", "SPARE", "DECRY"],
  ["CRIED", "TRIAD", "TALLY", "REALM"],
  ["STANK", "STAKE", "SKIFF", "SNIFF"],
  ["SHRUG", "WHEEL", "FETAL", "SUGAR"],
  ["SCORE", "OCTAL", "LABEL", "DRIED"],
  ["FILTH", "MINTY", "ATONE", "STANK"],
  ["HIPPY", "NICER", "VERSE", "SPASM"],
  ["SAFER", "CARGO", "AGING", "PENNY"],
  ["ANGLE", "KNIFE", "AFFIX", "PLAIT"],
  ["SWINE", "OWING", "SNAKY", "SNAKE"],
  ["HEART", "TENOR", "WOKEN", "CRIED"],
  ["GRAND", "TRASH", "ISSUE", "ENSUE"],
  ["BIRTH", "BIRCH", "SCANT", "STING"],
  ["PLAIN", "FLUFF", "AFIRE", "FIBRE"],
  ["UDDER", "IDIOM", "BOSOM", "BELOW"],
  ["WREAK", "CRATE", "ETHER", "FACET"],
  ["GUESS", "PULPY", "APTLY", "PSALM"],
  ["IMBUE", "SMIRK", "WRYLY", "BUILD"],
  ["CLAMP", "PLACE", "SCRUM", "IMBUE"],
  ["WORDY", "MOLDY", "ADORE", "ADORN"],
  ["STARK", "STOVE", "EVADE", "CRUDE"],
  ["THING", "PHONE", "INERT", "SNORE"],
  ["BLANK", "CLIFF", "AFIRE", "ENTRY"],
  ["PALER", "LAGER", "GENRE", "HEARD"],
  ["CAPER", "BADLY", "SLOTH", "PESTO"],
  ["ETHOS", "ATTIC", "LINER", "SOBER"],
  ["SLUNK", "BLAND", "ANVIL", "UNTIL"],
  ["PHONY", "CHICK", "SCOOP", "ENDOW"],
  ["SIREN", "DINGY", "AGAPE", "TEMPO"],
  ["RUDER", "RUSTY", "STAGE", "MERGE"],
  ["PHONE", "THINK", "SNORE", "SNARL"],
  ["SMEAR", "SMART", "FRILL", "EARLY"],
  ["KEBAB", "MEATY", "STINT", "RAINY"],
  ["ARGUE", "FRUIT", "KINKY", "MUSKY"],
  ["PERKY", "HEATH", "STIFF", "SKIFF"],
  ["CHUTE", "CHEEK", "FETUS", "STRUT"],
  ["STRAY", "STEEL", "TENOR", "BACON"],
  ["WEARY", "DETER", "TEACH", "CRICK"],
  ["PARTY", "DAILY", "CLUCK", "STOCK"],
  ["BUNNY", "RUDER", "BERET", "ANGER"],
  ["PHOTO", "CHEEK", "VEGAN", "STRAP"],
  ["ALONE", "BLEND", "SNIFF", "UNIFY"],
  ["RISKY", "PIANO", "INFER", "SKIER"],
  ["QUERY", "QUOTA", "STUNT", "GRAND"],
  ["BELOW", "WEARY", "BRACE", "TORCH"],
  ["TIMER", "TITHE", "SHAKY", "LEAKY"],
  ["TRASH", "CRYPT", "APART", "USURP"],
  ["BLESS", "ELFIN", "BICEP", "ISLET"],
  ["MIDST", "MILKY", "EKING", "USING"],
  ["ALBUM", "CLACK", "SCRUB", "QUEUE"],
  ["FINAL", "FIBER", "REACT", "MARCH"],
  ["BEING", "MELEE", "LEARN", "SNARL"],
  ["BIGOT", "DIGIT", "DITCH", "NOTCH"],
  ["BLEAK", "PLAZA", "OZONE", "SAUNA"],
  ["SPOOF", "APART", "CRASH", "WORST"],
  ["LEARN", "SERUM", "FULLY", "TRULY"],
  ["RATIO", "MARRY", "BREED", "PIXEL"],
];

// 365 days of revealed coordinates
const REVEALED_COORDINATES = [
  [
    [3, 2],
    [3, 0],
    [3, 3],
    [1, 2],
  ],
  [
    [2, 3],
    [4, 3],
    [3, 2],
    [3, 3],
  ],
  [
    [1, 4],
    [1, 1],
    [1, 0],
    [3, 2],
  ],
  [
    [1, 0],
    [4, 1],
    [1, 1],
    [3, 3],
  ],
  [
    [0, 1],
    [3, 0],
    [1, 4],
    [3, 2],
  ],
  [
    [2, 3],
    [4, 1],
    [3, 3],
    [1, 3],
  ],
  [
    [3, 4],
    [4, 1],
    [3, 0],
    [1, 3],
  ],
  [
    [0, 1],
    [1, 1],
    [1, 2],
    [3, 4],
  ],
  [
    [4, 1],
    [0, 1],
    [1, 1],
    [3, 4],
  ],
  [
    [1, 3],
    [1, 1],
    [3, 4],
    [4, 1],
  ],
  [
    [1, 1],
    [3, 4],
    [2, 1],
    [1, 4],
  ],
  [
    [1, 4],
    [2, 3],
    [1, 2],
    [3, 1],
  ],
  [
    [2, 3],
    [3, 4],
    [4, 3],
    [4, 1],
  ],
  [
    [0, 3],
    [3, 0],
    [3, 4],
    [1, 2],
  ],
  [
    [4, 3],
    [2, 3],
    [4, 1],
    [0, 1],
  ],
  [
    [2, 1],
    [3, 0],
    [1, 2],
    [1, 1],
  ],
  [
    [1, 0],
    [3, 2],
    [1, 3],
    [4, 3],
  ],
  [
    [2, 1],
    [1, 3],
    [3, 3],
    [4, 3],
  ],
  [
    [4, 1],
    [3, 0],
    [3, 3],
    [0, 3],
  ],
  [
    [1, 4],
    [0, 1],
    [3, 2],
    [1, 3],
  ],
  [
    [3, 0],
    [1, 1],
    [3, 3],
    [1, 0],
  ],
  [
    [2, 1],
    [1, 1],
    [0, 3],
    [4, 3],
  ],
  [
    [1, 3],
    [1, 2],
    [4, 1],
    [3, 1],
  ],
  [
    [1, 3],
    [1, 4],
    [3, 2],
    [1, 1],
  ],
  [
    [4, 1],
    [3, 0],
    [4, 3],
    [1, 1],
  ],
  [
    [3, 2],
    [1, 0],
    [0, 1],
    [1, 2],
  ],
  [
    [4, 1],
    [3, 3],
    [0, 3],
    [3, 0],
  ],
  [
    [1, 0],
    [0, 3],
    [4, 1],
    [3, 3],
  ],
  [
    [1, 4],
    [3, 2],
    [2, 3],
    [1, 0],
  ],
  [
    [3, 4],
    [3, 3],
    [1, 3],
    [1, 0],
  ],
  [
    [1, 0],
    [2, 1],
    [1, 4],
    [4, 1],
  ],
  [
    [3, 4],
    [0, 3],
    [1, 3],
    [1, 2],
  ],
  [
    [3, 1],
    [1, 0],
    [2, 3],
    [4, 1],
  ],
  [
    [0, 1],
    [0, 3],
    [1, 3],
    [4, 1],
  ],
  [
    [1, 4],
    [4, 3],
    [3, 0],
    [3, 3],
  ],
  [
    [1, 1],
    [0, 1],
    [3, 4],
    [3, 1],
  ],
  [
    [1, 4],
    [1, 1],
    [2, 3],
    [4, 1],
  ],
  [
    [4, 3],
    [1, 0],
    [1, 4],
    [0, 1],
  ],
  [
    [4, 3],
    [0, 1],
    [1, 1],
    [3, 0],
  ],
  [
    [1, 1],
    [0, 3],
    [1, 0],
    [2, 1],
  ],
  [
    [2, 3],
    [2, 1],
    [1, 3],
    [3, 2],
  ],
  [
    [1, 1],
    [1, 4],
    [3, 0],
    [4, 1],
  ],
  [
    [3, 4],
    [2, 3],
    [0, 1],
    [1, 2],
  ],
  [
    [1, 3],
    [1, 1],
    [3, 0],
    [0, 3],
  ],
  [
    [3, 3],
    [1, 2],
    [0, 3],
    [0, 1],
  ],
  [
    [1, 0],
    [0, 3],
    [3, 1],
    [4, 3],
  ],
  [
    [2, 1],
    [3, 1],
    [1, 0],
    [1, 4],
  ],
  [
    [3, 4],
    [1, 3],
    [2, 3],
    [3, 0],
  ],
  [
    [3, 0],
    [1, 4],
    [1, 0],
    [1, 2],
  ],
  [
    [0, 3],
    [3, 4],
    [1, 3],
    [4, 3],
  ],
  [
    [0, 3],
    [1, 3],
    [1, 4],
    [3, 0],
  ],
  [
    [2, 1],
    [0, 3],
    [3, 3],
    [2, 3],
  ],
  [
    [1, 4],
    [3, 1],
    [2, 1],
    [1, 2],
  ],
  [
    [2, 1],
    [1, 4],
    [0, 1],
    [3, 2],
  ],
  [
    [1, 1],
    [0, 3],
    [4, 1],
    [1, 4],
  ],
  [
    [4, 3],
    [2, 1],
    [4, 1],
    [0, 1],
  ],
  [
    [3, 4],
    [1, 4],
    [3, 0],
    [1, 3],
  ],
  [
    [3, 4],
    [3, 3],
    [0, 3],
    [1, 0],
  ],
  [
    [0, 1],
    [3, 3],
    [1, 0],
    [3, 0],
  ],
  [
    [3, 3],
    [4, 3],
    [1, 4],
    [0, 3],
  ],
  [
    [1, 3],
    [4, 1],
    [3, 0],
    [0, 1],
  ],
  [
    [1, 4],
    [2, 3],
    [3, 0],
    [1, 3],
  ],
  [
    [3, 2],
    [1, 1],
    [3, 0],
    [1, 2],
  ],
  [
    [2, 3],
    [1, 4],
    [1, 2],
    [3, 4],
  ],
  [
    [1, 3],
    [2, 1],
    [3, 1],
    [1, 2],
  ],
  [
    [3, 0],
    [3, 4],
    [0, 1],
    [2, 1],
  ],
  [
    [3, 0],
    [4, 1],
    [1, 4],
    [3, 2],
  ],
  [
    [1, 4],
    [3, 3],
    [2, 3],
    [1, 1],
  ],
  [
    [1, 2],
    [1, 0],
    [3, 4],
    [0, 3],
  ],
  [
    [1, 1],
    [1, 2],
    [3, 4],
    [2, 1],
  ],
  [
    [4, 1],
    [4, 3],
    [2, 3],
    [1, 2],
  ],
  [
    [2, 1],
    [4, 1],
    [3, 0],
    [0, 3],
  ],
  [
    [3, 2],
    [1, 1],
    [4, 1],
    [3, 3],
  ],
  [
    [0, 1],
    [1, 3],
    [1, 1],
    [3, 3],
  ],
  [
    [4, 1],
    [3, 3],
    [1, 4],
    [0, 3],
  ],
  [
    [4, 3],
    [1, 2],
    [3, 3],
    [1, 0],
  ],
  [
    [0, 3],
    [1, 2],
    [4, 1],
    [1, 3],
  ],
  [
    [1, 3],
    [4, 3],
    [3, 3],
    [3, 4],
  ],
  [
    [3, 3],
    [4, 3],
    [3, 0],
    [1, 0],
  ],
  [
    [3, 0],
    [4, 3],
    [0, 1],
    [0, 3],
  ],
  [
    [1, 2],
    [3, 2],
    [1, 3],
    [0, 1],
  ],
  [
    [1, 2],
    [3, 2],
    [3, 0],
    [2, 3],
  ],
  [
    [3, 1],
    [4, 3],
    [2, 1],
    [3, 2],
  ],
  [
    [1, 3],
    [2, 3],
    [3, 3],
    [1, 0],
  ],
  [
    [3, 0],
    [3, 1],
    [1, 0],
    [3, 2],
  ],
  [
    [0, 3],
    [4, 1],
    [3, 3],
    [1, 3],
  ],
  [
    [4, 3],
    [1, 3],
    [1, 1],
    [1, 0],
  ],
  [
    [2, 3],
    [0, 1],
    [0, 3],
    [1, 0],
  ],
  [
    [1, 3],
    [3, 0],
    [0, 3],
    [3, 3],
  ],
  [
    [4, 1],
    [3, 3],
    [1, 0],
    [3, 2],
  ],
  [
    [0, 1],
    [3, 0],
    [1, 4],
    [0, 3],
  ],
  [
    [1, 4],
    [0, 1],
    [3, 0],
    [3, 2],
  ],
  [
    [1, 2],
    [1, 4],
    [4, 3],
    [3, 1],
  ],
  [
    [3, 4],
    [2, 3],
    [3, 2],
    [2, 1],
  ],
  [
    [1, 4],
    [1, 0],
    [3, 4],
    [2, 1],
  ],
  [
    [4, 3],
    [3, 2],
    [0, 1],
    [1, 4],
  ],
  [
    [1, 0],
    [0, 1],
    [2, 1],
    [0, 3],
  ],
  [
    [3, 0],
    [4, 1],
    [1, 2],
    [3, 3],
  ],
  [
    [3, 3],
    [4, 1],
    [2, 3],
    [3, 1],
  ],
  [
    [1, 0],
    [3, 4],
    [3, 3],
    [1, 4],
  ],
  [
    [3, 2],
    [3, 4],
    [4, 3],
    [2, 1],
  ],
  [
    [0, 1],
    [3, 0],
    [3, 3],
    [0, 3],
  ],
  [
    [3, 4],
    [4, 1],
    [1, 3],
    [3, 3],
  ],
  [
    [3, 1],
    [0, 1],
    [1, 1],
    [3, 0],
  ],
  [
    [0, 3],
    [4, 3],
    [4, 1],
    [3, 0],
  ],
  [
    [4, 3],
    [2, 1],
    [3, 3],
    [3, 2],
  ],
  [
    [1, 3],
    [3, 1],
    [4, 3],
    [2, 3],
  ],
  [
    [0, 3],
    [1, 2],
    [0, 1],
    [2, 3],
  ],
  [
    [0, 1],
    [1, 4],
    [4, 3],
    [3, 2],
  ],
  [
    [2, 1],
    [3, 0],
    [0, 3],
    [1, 1],
  ],
  [
    [1, 4],
    [1, 0],
    [3, 2],
    [1, 2],
  ],
  [
    [2, 3],
    [3, 4],
    [4, 1],
    [4, 3],
  ],
  [
    [1, 3],
    [1, 2],
    [4, 1],
    [3, 4],
  ],
  [
    [2, 3],
    [2, 1],
    [4, 3],
    [1, 1],
  ],
  [
    [4, 3],
    [1, 4],
    [0, 3],
    [3, 3],
  ],
  [
    [2, 3],
    [1, 4],
    [1, 0],
    [0, 3],
  ],
  [
    [4, 3],
    [3, 3],
    [1, 1],
    [3, 2],
  ],
  [
    [1, 0],
    [0, 3],
    [2, 3],
    [1, 2],
  ],
  [
    [1, 3],
    [3, 3],
    [3, 2],
    [1, 0],
  ],
  [
    [1, 4],
    [4, 3],
    [3, 2],
    [1, 1],
  ],
  [
    [4, 3],
    [1, 4],
    [3, 0],
    [1, 0],
  ],
  [
    [1, 4],
    [1, 2],
    [2, 3],
    [3, 0],
  ],
  [
    [1, 2],
    [1, 3],
    [3, 3],
    [2, 3],
  ],
  [
    [0, 3],
    [3, 0],
    [3, 1],
    [3, 3],
  ],
  [
    [1, 2],
    [1, 1],
    [3, 0],
    [2, 3],
  ],
  [
    [1, 2],
    [1, 1],
    [1, 0],
    [3, 0],
  ],
  [
    [1, 4],
    [2, 3],
    [3, 4],
    [0, 1],
  ],
  [
    [1, 3],
    [4, 3],
    [1, 0],
    [3, 1],
  ],
  [
    [1, 2],
    [1, 0],
    [1, 3],
    [4, 1],
  ],
  [
    [1, 3],
    [1, 2],
    [3, 2],
    [3, 3],
  ],
  [
    [1, 3],
    [1, 4],
    [3, 3],
    [4, 3],
  ],
  [
    [2, 3],
    [3, 1],
    [1, 4],
    [2, 1],
  ],
  [
    [4, 1],
    [1, 2],
    [1, 4],
    [1, 1],
  ],
  [
    [3, 1],
    [1, 0],
    [0, 1],
    [4, 1],
  ],
  [
    [3, 0],
    [3, 2],
    [1, 1],
    [1, 2],
  ],
  [
    [1, 3],
    [1, 2],
    [4, 1],
    [1, 4],
  ],
  [
    [4, 1],
    [1, 0],
    [0, 3],
    [1, 1],
  ],
  [
    [3, 1],
    [3, 3],
    [3, 4],
    [1, 3],
  ],
  [
    [3, 2],
    [3, 4],
    [1, 2],
    [1, 4],
  ],
  [
    [3, 2],
    [4, 1],
    [0, 1],
    [2, 1],
  ],
  [
    [1, 2],
    [1, 4],
    [0, 1],
    [2, 1],
  ],
  [
    [1, 4],
    [1, 2],
    [1, 3],
    [1, 0],
  ],
  [
    [1, 3],
    [3, 3],
    [4, 1],
    [3, 4],
  ],
  [
    [1, 0],
    [1, 3],
    [2, 3],
    [3, 0],
  ],
  [
    [3, 3],
    [0, 1],
    [2, 3],
    [4, 1],
  ],
  [
    [0, 3],
    [3, 4],
    [1, 1],
    [3, 0],
  ],
  [
    [3, 1],
    [4, 1],
    [2, 1],
    [2, 3],
  ],
  [
    [0, 3],
    [1, 2],
    [3, 0],
    [1, 1],
  ],
  [
    [3, 2],
    [1, 3],
    [1, 1],
    [0, 3],
  ],
  [
    [2, 3],
    [3, 2],
    [1, 2],
    [3, 0],
  ],
  [
    [2, 1],
    [1, 3],
    [0, 3],
    [3, 0],
  ],
  [
    [1, 4],
    [4, 3],
    [0, 3],
    [1, 2],
  ],
  [
    [2, 1],
    [3, 3],
    [3, 1],
    [3, 4],
  ],
  [
    [0, 3],
    [1, 2],
    [3, 0],
    [2, 3],
  ],
  [
    [3, 1],
    [3, 4],
    [3, 3],
    [1, 1],
  ],
  [
    [3, 4],
    [4, 3],
    [2, 3],
    [2, 1],
  ],
  [
    [1, 0],
    [1, 3],
    [4, 3],
    [4, 1],
  ],
  [
    [0, 3],
    [0, 1],
    [3, 4],
    [3, 2],
  ],
  [
    [4, 1],
    [0, 1],
    [2, 1],
    [1, 3],
  ],
  [
    [1, 2],
    [3, 3],
    [1, 1],
    [2, 1],
  ],
  [
    [1, 3],
    [4, 3],
    [0, 1],
    [0, 3],
  ],
  [
    [1, 2],
    [4, 1],
    [3, 0],
    [1, 3],
  ],
  [
    [1, 1],
    [4, 1],
    [1, 4],
    [3, 4],
  ],
  [
    [3, 2],
    [4, 3],
    [1, 0],
    [1, 4],
  ],
  [
    [1, 1],
    [4, 3],
    [1, 2],
    [3, 2],
  ],
  [
    [0, 3],
    [1, 2],
    [2, 3],
    [1, 3],
  ],
  [
    [0, 3],
    [3, 0],
    [2, 1],
    [3, 4],
  ],
  [
    [1, 2],
    [4, 3],
    [3, 0],
    [0, 3],
  ],
  [
    [1, 4],
    [3, 1],
    [0, 3],
    [2, 1],
  ],
  [
    [0, 3],
    [3, 1],
    [2, 1],
    [3, 4],
  ],
  [
    [4, 3],
    [1, 3],
    [0, 1],
    [1, 4],
  ],
  [
    [2, 3],
    [1, 2],
    [1, 1],
    [3, 2],
  ],
  [
    [1, 2],
    [4, 3],
    [1, 3],
    [2, 3],
  ],
  [
    [1, 2],
    [3, 2],
    [1, 3],
    [3, 1],
  ],
  [
    [2, 3],
    [3, 0],
    [0, 3],
    [1, 1],
  ],
  [
    [3, 1],
    [1, 3],
    [1, 0],
    [0, 1],
  ],
  [
    [3, 3],
    [4, 3],
    [2, 1],
    [2, 3],
  ],
  [
    [1, 4],
    [3, 1],
    [4, 3],
    [3, 3],
  ],
  [
    [1, 3],
    [3, 1],
    [0, 1],
    [2, 3],
  ],
  [
    [1, 0],
    [3, 0],
    [0, 1],
    [0, 3],
  ],
  [
    [0, 3],
    [3, 3],
    [1, 3],
    [1, 1],
  ],
  [
    [2, 1],
    [3, 3],
    [1, 1],
    [1, 0],
  ],
  [
    [1, 2],
    [1, 3],
    [3, 0],
    [0, 3],
  ],
  [
    [3, 2],
    [2, 3],
    [1, 2],
    [3, 0],
  ],
  [
    [1, 3],
    [0, 1],
    [3, 3],
    [0, 3],
  ],
  [
    [2, 3],
    [1, 0],
    [3, 2],
    [1, 3],
  ],
  [
    [1, 0],
    [3, 4],
    [1, 1],
    [2, 1],
  ],
  [
    [0, 1],
    [4, 1],
    [2, 3],
    [3, 0],
  ],
  [
    [1, 3],
    [2, 3],
    [3, 3],
    [0, 1],
  ],
  [
    [0, 3],
    [3, 1],
    [1, 0],
    [4, 3],
  ],
  [
    [1, 3],
    [1, 2],
    [0, 3],
    [1, 1],
  ],
  [
    [1, 0],
    [4, 3],
    [1, 1],
    [3, 0],
  ],
  [
    [1, 2],
    [4, 1],
    [3, 0],
    [3, 3],
  ],
  [
    [4, 1],
    [4, 3],
    [2, 3],
    [0, 3],
  ],
  [
    [1, 0],
    [3, 1],
    [4, 3],
    [3, 3],
  ],
  [
    [1, 1],
    [1, 3],
    [1, 4],
    [3, 2],
  ],
  [
    [1, 1],
    [3, 4],
    [4, 1],
    [1, 3],
  ],
  [
    [2, 3],
    [1, 3],
    [4, 1],
    [1, 1],
  ],
  [
    [0, 1],
    [1, 4],
    [0, 3],
    [3, 4],
  ],
  [
    [0, 1],
    [3, 0],
    [1, 4],
    [3, 2],
  ],
  [
    [4, 3],
    [3, 3],
    [3, 0],
    [3, 1],
  ],
  [
    [3, 4],
    [1, 1],
    [0, 1],
    [3, 1],
  ],
  [
    [1, 0],
    [0, 1],
    [3, 2],
    [3, 1],
  ],
  [
    [3, 2],
    [0, 3],
    [3, 1],
    [1, 3],
  ],
  [
    [3, 2],
    [1, 2],
    [4, 3],
    [3, 3],
  ],
  [
    [0, 3],
    [1, 0],
    [3, 1],
    [2, 3],
  ],
  [
    [4, 3],
    [3, 1],
    [0, 1],
    [3, 3],
  ],
  [
    [1, 1],
    [4, 1],
    [0, 1],
    [0, 3],
  ],
  [
    [3, 0],
    [2, 1],
    [1, 0],
    [2, 3],
  ],
  [
    [2, 3],
    [3, 0],
    [4, 1],
    [2, 1],
  ],
  [
    [2, 1],
    [1, 0],
    [3, 3],
    [0, 1],
  ],
  [
    [1, 2],
    [3, 4],
    [4, 3],
    [1, 4],
  ],
  [
    [1, 0],
    [0, 1],
    [3, 0],
    [1, 2],
  ],
  [
    [1, 4],
    [2, 3],
    [1, 2],
    [4, 1],
  ],
  [
    [1, 1],
    [4, 1],
    [2, 1],
    [1, 0],
  ],
  [
    [3, 3],
    [1, 0],
    [3, 4],
    [0, 1],
  ],
  [
    [3, 4],
    [4, 3],
    [3, 0],
    [0, 1],
  ],
  [
    [3, 4],
    [3, 1],
    [1, 3],
    [3, 3],
  ],
  [
    [1, 4],
    [2, 1],
    [2, 3],
    [3, 0],
  ],
  [
    [2, 3],
    [3, 2],
    [3, 3],
    [2, 1],
  ],
  [
    [4, 3],
    [3, 0],
    [1, 1],
    [4, 1],
  ],
  [
    [2, 1],
    [1, 1],
    [3, 2],
    [4, 3],
  ],
  [
    [2, 1],
    [3, 2],
    [1, 4],
    [3, 4],
  ],
  [
    [1, 3],
    [1, 2],
    [2, 3],
    [1, 0],
  ],
  [
    [3, 1],
    [4, 1],
    [3, 3],
    [0, 1],
  ],
  [
    [2, 3],
    [3, 3],
    [3, 2],
    [1, 4],
  ],
  [
    [0, 3],
    [2, 3],
    [1, 2],
    [1, 0],
  ],
  [
    [3, 1],
    [3, 4],
    [1, 2],
    [1, 3],
  ],
  [
    [4, 3],
    [4, 1],
    [1, 2],
    [1, 4],
  ],
  [
    [0, 3],
    [3, 1],
    [4, 3],
    [4, 1],
  ],
  [
    [1, 2],
    [3, 3],
    [1, 1],
    [1, 0],
  ],
  [
    [1, 0],
    [3, 4],
    [3, 0],
    [0, 3],
  ],
  [
    [1, 2],
    [3, 2],
    [2, 3],
    [3, 0],
  ],
  [
    [4, 1],
    [3, 3],
    [3, 2],
    [4, 3],
  ],
  [
    [3, 0],
    [2, 1],
    [3, 3],
    [0, 3],
  ],
  [
    [3, 0],
    [0, 3],
    [3, 1],
    [1, 1],
  ],
  [
    [3, 3],
    [1, 2],
    [4, 3],
    [3, 1],
  ],
  [
    [1, 2],
    [1, 4],
    [2, 3],
    [4, 1],
  ],
  [
    [3, 2],
    [3, 3],
    [2, 1],
    [3, 4],
  ],
  [
    [3, 4],
    [4, 1],
    [3, 3],
    [1, 3],
  ],
  [
    [1, 0],
    [4, 3],
    [1, 2],
    [3, 3],
  ],
  [
    [2, 3],
    [4, 3],
    [1, 0],
    [3, 2],
  ],
  [
    [0, 3],
    [0, 1],
    [1, 4],
    [1, 3],
  ],
  [
    [2, 3],
    [1, 3],
    [0, 3],
    [3, 0],
  ],
  [
    [1, 1],
    [1, 4],
    [0, 3],
    [3, 1],
  ],
  [
    [3, 1],
    [1, 1],
    [1, 2],
    [2, 1],
  ],
  [
    [1, 1],
    [0, 3],
    [2, 3],
    [1, 3],
  ],
  [
    [0, 1],
    [3, 0],
    [3, 2],
    [4, 3],
  ],
  [
    [1, 2],
    [3, 0],
    [3, 4],
    [1, 3],
  ],
  [
    [4, 1],
    [3, 0],
    [0, 1],
    [1, 3],
  ],
  [
    [1, 4],
    [0, 3],
    [1, 1],
    [3, 0],
  ],
  [
    [4, 3],
    [3, 3],
    [1, 4],
    [1, 0],
  ],
  [
    [1, 1],
    [2, 3],
    [0, 1],
    [2, 1],
  ],
  [
    [3, 2],
    [3, 4],
    [2, 1],
    [3, 1],
  ],
  [
    [2, 1],
    [4, 1],
    [3, 0],
    [0, 1],
  ],
  [
    [1, 1],
    [0, 1],
    [3, 3],
    [0, 3],
  ],
  [
    [1, 1],
    [1, 4],
    [4, 1],
    [2, 1],
  ],
  [
    [2, 1],
    [3, 3],
    [0, 3],
    [3, 4],
  ],
  [
    [3, 4],
    [0, 3],
    [3, 3],
    [1, 3],
  ],
  [
    [3, 1],
    [3, 4],
    [4, 3],
    [0, 3],
  ],
  [
    [0, 3],
    [1, 1],
    [1, 4],
    [2, 1],
  ],
  [
    [1, 0],
    [0, 1],
    [3, 1],
    [3, 2],
  ],
  [
    [1, 4],
    [0, 3],
    [1, 0],
    [1, 3],
  ],
  [
    [2, 1],
    [1, 4],
    [2, 3],
    [3, 2],
  ],
  [
    [2, 1],
    [2, 3],
    [3, 4],
    [0, 1],
  ],
  [
    [1, 4],
    [0, 3],
    [1, 0],
    [4, 1],
  ],
  [
    [3, 2],
    [3, 1],
    [4, 1],
    [1, 2],
  ],
  [
    [1, 0],
    [3, 3],
    [2, 1],
    [1, 1],
  ],
  [
    [3, 4],
    [1, 1],
    [3, 3],
    [0, 3],
  ],
  [
    [3, 0],
    [0, 3],
    [0, 1],
    [1, 4],
  ],
  [
    [1, 1],
    [2, 1],
    [2, 3],
    [1, 0],
  ],
  [
    [1, 1],
    [1, 4],
    [1, 0],
    [2, 3],
  ],
  [
    [3, 1],
    [1, 4],
    [0, 1],
    [1, 3],
  ],
  [
    [3, 0],
    [4, 1],
    [3, 2],
    [2, 1],
  ],
  [
    [2, 3],
    [4, 1],
    [1, 3],
    [1, 0],
  ],
  [
    [3, 0],
    [2, 1],
    [3, 1],
    [3, 3],
  ],
  [
    [1, 4],
    [1, 0],
    [0, 3],
    [3, 4],
  ],
  [
    [1, 4],
    [0, 1],
    [1, 0],
    [0, 3],
  ],
  [
    [4, 3],
    [0, 1],
    [0, 3],
    [1, 4],
  ],
  [
    [2, 1],
    [3, 4],
    [0, 1],
    [2, 3],
  ],
  [
    [1, 4],
    [3, 0],
    [3, 4],
    [1, 3],
  ],
  [
    [3, 3],
    [1, 1],
    [1, 3],
    [1, 4],
  ],
  [
    [3, 3],
    [3, 2],
    [2, 1],
    [4, 1],
  ],
  [
    [4, 3],
    [1, 0],
    [0, 3],
    [0, 1],
  ],
  [
    [1, 3],
    [0, 1],
    [1, 0],
    [3, 2],
  ],
  [
    [0, 1],
    [2, 1],
    [1, 1],
    [1, 2],
  ],
  [
    [3, 0],
    [1, 3],
    [4, 1],
    [2, 1],
  ],
  [
    [1, 2],
    [0, 3],
    [4, 3],
    [2, 3],
  ],
  [
    [3, 1],
    [4, 3],
    [3, 4],
    [2, 3],
  ],
  [
    [2, 3],
    [3, 3],
    [4, 3],
    [3, 1],
  ],
  [
    [1, 2],
    [0, 1],
    [3, 2],
    [4, 1],
  ],
  [
    [2, 1],
    [3, 4],
    [3, 2],
    [1, 1],
  ],
  [
    [0, 3],
    [3, 3],
    [3, 4],
    [1, 0],
  ],
  [
    [4, 1],
    [0, 1],
    [2, 1],
    [1, 4],
  ],
  [
    [2, 1],
    [2, 3],
    [3, 1],
    [1, 2],
  ],
  [
    [4, 3],
    [1, 4],
    [0, 3],
    [2, 3],
  ],
  [
    [1, 3],
    [1, 1],
    [1, 4],
    [4, 3],
  ],
  [
    [3, 3],
    [1, 1],
    [1, 0],
    [3, 1],
  ],
  [
    [1, 1],
    [4, 1],
    [4, 3],
    [3, 2],
  ],
  [
    [4, 3],
    [1, 0],
    [2, 3],
    [0, 3],
  ],
  [
    [1, 0],
    [3, 3],
    [3, 1],
    [3, 0],
  ],
  [
    [3, 2],
    [0, 1],
    [4, 3],
    [4, 1],
  ],
  [
    [4, 1],
    [3, 3],
    [4, 3],
    [0, 1],
  ],
  [
    [3, 3],
    [3, 2],
    [2, 1],
    [1, 4],
  ],
  [
    [1, 2],
    [1, 0],
    [1, 3],
    [0, 3],
  ],
  [
    [0, 3],
    [3, 4],
    [3, 1],
    [3, 2],
  ],
  [
    [0, 3],
    [1, 3],
    [1, 0],
    [2, 1],
  ],
  [
    [3, 3],
    [2, 3],
    [4, 1],
    [1, 0],
  ],
  [
    [1, 0],
    [4, 1],
    [1, 1],
    [2, 3],
  ],
  [
    [0, 3],
    [1, 1],
    [1, 2],
    [3, 3],
  ],
  [
    [3, 0],
    [4, 3],
    [2, 3],
    [2, 1],
  ],
  [
    [3, 1],
    [1, 0],
    [0, 3],
    [2, 1],
  ],
  [
    [4, 1],
    [2, 3],
    [1, 2],
    [2, 1],
  ],
  [
    [1, 4],
    [4, 3],
    [1, 1],
    [3, 3],
  ],
  [
    [3, 1],
    [1, 1],
    [0, 3],
    [1, 4],
  ],
  [
    [0, 3],
    [1, 3],
    [2, 3],
    [1, 1],
  ],
  [
    [1, 1],
    [3, 0],
    [4, 3],
    [1, 2],
  ],
  [
    [4, 1],
    [4, 3],
    [1, 1],
    [1, 0],
  ],
  [
    [1, 3],
    [1, 1],
    [1, 0],
    [1, 4],
  ],
  [
    [1, 0],
    [1, 4],
    [1, 2],
    [3, 1],
  ],
  [
    [3, 4],
    [0, 1],
    [3, 1],
    [1, 1],
  ],
  [
    [1, 2],
    [1, 1],
    [4, 1],
    [3, 2],
  ],
  [
    [3, 1],
    [2, 1],
    [1, 4],
    [2, 3],
  ],
  [
    [0, 3],
    [1, 3],
    [2, 1],
    [1, 2],
  ],
  [
    [2, 3],
    [1, 4],
    [1, 2],
    [3, 2],
  ],
  [
    [3, 1],
    [4, 3],
    [0, 1],
    [1, 0],
  ],
  [
    [3, 1],
    [4, 3],
    [3, 2],
    [1, 4],
  ],
  [
    [0, 1],
    [3, 3],
    [1, 4],
    [3, 2],
  ],
  [
    [4, 1],
    [1, 1],
    [1, 4],
    [4, 3],
  ],
  [
    [3, 3],
    [1, 1],
    [0, 3],
    [1, 0],
  ],
  [
    [3, 4],
    [0, 1],
    [2, 3],
    [4, 1],
  ],
  [
    [3, 4],
    [4, 1],
    [1, 0],
    [1, 4],
  ],
  [
    [3, 0],
    [4, 1],
    [0, 3],
    [1, 0],
  ],
  [
    [0, 1],
    [4, 3],
    [2, 3],
    [1, 4],
  ],
  [
    [2, 3],
    [0, 3],
    [1, 1],
    [3, 1],
  ],
  [
    [3, 1],
    [2, 1],
    [3, 2],
    [4, 1],
  ],
  [
    [1, 1],
    [0, 1],
    [2, 3],
    [4, 3],
  ],
  [
    [1, 0],
    [3, 3],
    [1, 4],
    [1, 2],
  ],
  [
    [3, 3],
    [3, 2],
    [4, 1],
    [0, 3],
  ],
  [
    [4, 1],
    [1, 2],
    [1, 4],
    [1, 0],
  ],
  [
    [3, 0],
    [2, 3],
    [1, 1],
    [3, 3],
  ],
  [
    [1, 0],
    [3, 2],
    [0, 3],
    [1, 3],
  ],
  [
    [4, 1],
    [1, 3],
    [3, 3],
    [3, 2],
  ],
  [
    [3, 4],
    [3, 3],
    [3, 0],
    [1, 4],
  ],
  [
    [1, 2],
    [4, 1],
    [1, 1],
    [2, 1],
  ],
  [
    [2, 3],
    [0, 3],
    [4, 3],
    [4, 1],
  ],
  [
    [4, 3],
    [0, 1],
    [2, 3],
    [1, 4],
  ],
  [
    [2, 1],
    [1, 4],
    [1, 3],
    [4, 3],
  ],
  [
    [4, 3],
    [4, 1],
    [1, 2],
    [2, 1],
  ],
  [
    [1, 2],
    [2, 3],
    [3, 2],
    [4, 3],
  ],
  [
    [3, 3],
    [2, 1],
    [1, 2],
    [1, 4],
  ],
  [
    [1, 2],
    [3, 4],
    [1, 4],
    [3, 2],
  ],
  [
    [3, 1],
    [3, 4],
    [1, 1],
    [3, 2],
  ],
  [
    [1, 4],
    [4, 3],
    [2, 3],
    [2, 1],
  ],
  [
    [1, 3],
    [3, 0],
    [1, 0],
    [4, 1],
  ],
  [
    [1, 2],
    [4, 1],
    [0, 1],
    [3, 4],
  ],
  [
    [2, 1],
    [3, 1],
    [3, 2],
    [1, 4],
  ],
  [
    [1, 3],
    [1, 1],
    [3, 2],
    [3, 4],
  ],
  [
    [1, 0],
    [3, 4],
    [2, 1],
    [1, 3],
  ],
  [
    [3, 3],
    [3, 2],
    [1, 0],
    [0, 3],
  ],
  [
    [3, 0],
    [3, 4],
    [4, 3],
    [2, 3],
  ],
  [
    [4, 3],
    [3, 2],
    [4, 1],
    [1, 4],
  ],
  [
    [3, 4],
    [2, 1],
    [3, 2],
    [2, 3],
  ],
  [
    [4, 3],
    [2, 3],
    [3, 4],
    [3, 1],
  ],
  [
    [2, 1],
    [3, 1],
    [0, 3],
    [1, 2],
  ],
];

// 365 days of puzzles
export const PUZZLES: Array<Puzzle> = WORD_QUARTETS.map((quartet, i) => {
  return {
    wordQuartet: quartet,
    revealedCoordinates: REVEALED_COORDINATES[i]
  }
})