import React from "react";
import "./GameEndOverlay.css";
import {GameOutcome} from "./types/GameState";
import ConfettiExplosion from 'react-confetti-explosion';

type Props = {
  outcome: GameOutcome;
  wordQuartet: Array<string>;
}

export default function GameEndOverlay({ outcome, wordQuartet }: Props) {
  if (outcome === 'pending') {
    return null;
  }

  if (outcome === 'won') {
    return (
      <div className="overlay">

        <div className="outcome-modal" style={{backgroundColor: 'lightgreen'}}>
          <ConfettiExplosion />
          <h3>Congratulations! You won :)</h3>
        </div>

      </div>
    )
  } else {
    return (
      <div className="overlay">
        <div className="outcome-modal" style={{backgroundColor: 'sandybrown'}}>
          <div style={{ marginBottom: 20 }}>
            <h2 style={{marginTop: 0, textAlign: "center"}}>Game over</h2> You did not get the words in 5 guesses:
          </div>
          <div style={{ marginBottom: 30, fontWeight: "bold" }}>
            { wordQuartet.join(', ') }
          </div>
          <div >
            Try again tomorrow!
          </div>
        </div>
      </div>
    )
  }
}