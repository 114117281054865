import {GuessOutcomeGrid, LetterOutcomeHash} from "../types/GameState";
import FULL_WORD_LIST from "../constants/fullWordList";
import { Grid } from "../types/Grid";
import { LetterOutcome } from "../types/WordGuessOutcome";
import { getColumnWordByIndex, getRowWordByIndex } from "../utils/grid";

export function didWin(guessOutcomeGrid: GuessOutcomeGrid): boolean {
  let correctCount = 0;
  for (let r = 0; r < guessOutcomeGrid.length; r++) {
    const row = guessOutcomeGrid[r];
    for (let c = 0; c < row.length; c++) {
      const guessOutcome = row[c];
      if (guessOutcome === LetterOutcome.CORRECT) {
        correctCount++;
      }
    }
  }
  return correctCount >= 16;
}

export function getGuessErrors(guessGrid: Grid, guessOutcomeGrid: GuessOutcomeGrid): Array<string> {
  const word1 = getColumnWordByIndex(guessGrid, 1).join('')
  const word2 = getRowWordByIndex(guessGrid, 1).join('')
  const word3 = getColumnWordByIndex(guessGrid, 3).join('')
  const word4 = getRowWordByIndex(guessGrid, 3).join('')

  if (containsPartiallyGuessedWord(guessGrid, guessOutcomeGrid)) {
    return ['You cannot partially guess a word']
  }

  if(word1.length === 5 && !FULL_WORD_LIST.includes(word1)) {
    return [`${word1.toUpperCase()} is not a valid word`]
  }

  if(word2.length === 5 && !FULL_WORD_LIST.includes(word2)) {
    return [`${word2.toUpperCase()} is not a valid word`]
  }

  if(word3.length === 5 && !FULL_WORD_LIST.includes(word3)) {
    return [`${word3.toUpperCase()} is not a valid word`]
  }

  if(word4.length === 5 && !FULL_WORD_LIST.includes(word4)) {
    return [`${word4.toUpperCase()} is not a valid word`]
  }

  return []
}

function containsPartiallyGuessedWord(guessGrid: Grid, guessOutcomeGrid: GuessOutcomeGrid): boolean {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (guessGrid[r][c] && guessOutcomeGrid[r][c] !== LetterOutcome.CORRECT && ([1, 3].includes(r) || [1, 3].includes(c))) {
        const words = getGridWordsForIndexes(guessGrid, r, c)
        if (!words.some(word => word.word.every(letter => Boolean(letter)))) {
          return true;
        }
      }
    }
  }

  return false;
}


export function getUpdatedRemainingLettersOutcomes(
  remainingLettersOutcomes: LetterOutcomeHash,
  guessGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
): LetterOutcomeHash {
  const updatedRemainingLettersOutcomes = {
    ...remainingLettersOutcomes,
  };

  identifyLettersOfOutcome(
    guessGrid,
    guessOutcomeGrid,
    LetterOutcome.INCORRECT,
    updatedRemainingLettersOutcomes
  );
  identifyLettersOfOutcome(
    guessGrid,
    guessOutcomeGrid,
    LetterOutcome.LETTER_ON_BOARD,
    updatedRemainingLettersOutcomes
  );
  identifyLettersOfOutcome(
    guessGrid,
    guessOutcomeGrid,
    LetterOutcome.LETTER_IN_WORD,
    updatedRemainingLettersOutcomes
  );
  identifyLettersOfOutcome(
    guessGrid,
    guessOutcomeGrid,
    LetterOutcome.CORRECT,
    updatedRemainingLettersOutcomes
  );

  return updatedRemainingLettersOutcomes
}

function identifyLettersOfOutcome(
  guessGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid,
  outcome: LetterOutcome,
  updatedRemainingLettersOutcomes: LetterOutcomeHash
): void {

  for (let r = 0; r < guessGrid.length; r++) {
    const row = guessGrid[r];
    for (let c = 0; c < row.length; c++) {
      const guessedLetter = row[c];
      if (guessedLetter) {
        if (
          hasGuessLetterOfOutcome(
            guessedLetter,
            guessGrid,
            guessOutcomeGrid,
            outcome
          )
        ) {
          updatedRemainingLettersOutcomes[guessedLetter] = outcome;
        }
      }
    }
  }
}

function hasGuessLetterOfOutcome(
  letter: string,
  guessGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid,
  outcome: LetterOutcome
): boolean {
  for (let r = 0; r < guessGrid.length; r++) {
    const row = guessGrid[r];
    for (let c = 0; c < row.length; c++) {
      const guessedLetter = row[c];
      if (guessedLetter === letter && guessOutcomeGrid[r][c] === outcome) {
        return true;
      }
    }
  }

  return false;
}

export function getResetGuessGridWithCorrectGuessesOnly(
  guessGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
): Grid {
  const updatedGuessGrid = JSON.parse(JSON.stringify(guessGrid))
  for (let r = 0; r < guessOutcomeGrid.length; r++) {
    const row = guessOutcomeGrid[r];
    for (let c = 0; c < row.length; c++) {
      const guessOutcome = row[c];
      if (guessOutcome !== LetterOutcome.CORRECT) {
        updatedGuessGrid[r][c] = null;
      }
    }
  }

  return updatedGuessGrid;
}

export function getGuessOutcomeGrid(
  guessGrid: Grid,
  answerGrid: Grid
): GuessOutcomeGrid {
  // Initialize outcome grid with null values
  const guessOutcomeGrid = [...Array(5)].map((e) =>
    Array(5).fill(LetterOutcome.NULL)
  );

  // Process no guesses
  processNoGuesses(guessGrid, answerGrid, guessOutcomeGrid);

  // console.log(
  //   "AFTER PROCESSING NO-GUESSES guessOutcomeGrid | evaluateGuess: L134"
  // );
  // console.log(JSON.parse(JSON.stringify(guessOutcomeGrid)));

  processGrays(guessGrid, guessOutcomeGrid);

  // console.log(
  //   "AFTER PROCESSING GRAYS guessOutcomeGrid | evaluateGuess: L141"
  // );
  // console.log(JSON.parse(JSON.stringify(guessOutcomeGrid)));

  // Process greens
  processGreens(guessGrid, answerGrid, guessOutcomeGrid);
  // console.log("AFTER PROCESSING GREENS guessOutcomeGrid | evaluateGuess: L147");
  // console.log(JSON.parse(JSON.stringify(guessOutcomeGrid)));

  // Process yellows
  processYellows(guessGrid, answerGrid, guessOutcomeGrid);
  // console.log(
  //   "AFTER PROCESSING YELLOWS guessOutcomeGrid | evaluateGuess: L153"
  // );
  // console.log(JSON.parse(JSON.stringify(guessOutcomeGrid)));

  // Process oranges
  processOranges(guessGrid, answerGrid, guessOutcomeGrid);
  // console.log(
  //   "AFTER PROCESSING ORANGES guessOutcomeGrid | evaluateGuess: L160"
  // );
  // console.log(JSON.parse(JSON.stringify(guessOutcomeGrid)));

  return guessOutcomeGrid;
}

function processGrays(
  guessGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
) {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (guessGrid[r][c]) {
        guessOutcomeGrid[r][c] = LetterOutcome.INCORRECT;
      }
    }
  }
}

function processOranges(
  guessGrid: Grid,
  answerGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
) {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (
        (r === 1 || r === 3 || c === 1 || c === 3) &&
        guessOutcomeGrid[r][c] !== LetterOutcome.CORRECT &&
        guessOutcomeGrid[r][c] !== LetterOutcome.LETTER_IN_WORD &&
        guessGrid[r][c]
      ) {
        const guessedLetter = guessGrid[r][c];
        const restOfBoardOutcomeGrid = createOutcomeGridMinusCoordinateWords(
          guessOutcomeGrid,
          r,
          c
        );

        if (
          doesRestOfBoardHaveAnOrangeMatchForLetter(
            restOfBoardOutcomeGrid,
            guessGrid,
            answerGrid,
            guessedLetter,
            r,
            c,
          )
        ) {
          guessOutcomeGrid[r][c] = LetterOutcome.LETTER_ON_BOARD;
        }
      }
    }
  }
}

function doesRestOfBoardHaveAnOrangeMatchForLetter(
  restOfBoardOutcomes: GuessOutcomeGrid,
  guessGrid: Grid,
  answerGrid: Grid,
  letter: string | null,
  letterRow: number,
  letterColumn: number,
): boolean {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (
        !(r === letterRow && c === letterColumn) &&
        !([1,3].includes(r) && r === letterRow) &&
        !([1,3].includes(c) && c === letterColumn) &&

        answerGrid[r][c] === letter
      ) {
        const touchingWordsOutcomeGrid =
          createOutcomeGridWithOnlyCoordinateWords(restOfBoardOutcomes, r, c);

        if (restOfBoardOutcomes[r][c] === LetterOutcome.CORRECT) {
          // Do nothing
        } else if (
          doesYellowExistInOutcomeGridForLetter(
            touchingWordsOutcomeGrid,
            guessGrid,
            letter
          )
        ) {
          // Do nothing
        } else {
          return true;
        }
      }
    }
  }

  return false;
}

function doesYellowExistInOutcomeGridForLetter(
  partialOutcomeGrid: GuessOutcomeGrid,
  guessGrid: Grid,
  letter: string | null
): boolean {
  for (let r = 0; r < partialOutcomeGrid.length; r++) {
    for (let c = 0; c < partialOutcomeGrid[r].length; c++) {
      if (
        guessGrid[r][c] === letter &&
        partialOutcomeGrid[r][c] === LetterOutcome.LETTER_IN_WORD
      ) {
        return true;
      }
    }
  }

  return false;
}

function createOutcomeGridMinusCoordinateWords(
  initialOutcomeGrid: GuessOutcomeGrid,
  rowIndex: number,
  columnIndex: number
): GuessOutcomeGrid {
  const newGuessOutcomeGrid = [...Array(5)].map((e) =>
    Array(5).fill(LetterOutcome.NULL)
  );

  for (let r = 0; r < initialOutcomeGrid.length; r++) {
    for (let c = 0; c < initialOutcomeGrid[r].length; c++) {
      if (
        ([1, 3].includes(r) && r === rowIndex) ||
        ([1, 3].includes(c) && c === columnIndex)
      ) {
        newGuessOutcomeGrid[r][c] = LetterOutcome.NO_GUESS;
      } else {
        newGuessOutcomeGrid[r][c] = initialOutcomeGrid[r][c];
      }
    }
  }

  return newGuessOutcomeGrid;
}

function createOutcomeGridWithOnlyCoordinateWords(
  initialOutcomeGrid: GuessOutcomeGrid,
  rowIndex: number,
  columnIndex: number
): GuessOutcomeGrid {
  const newGuessOutcomeGrid = [...Array(5)].map((e) =>
    Array(5).fill(LetterOutcome.NULL)
  );

  for (let r = 0; r < initialOutcomeGrid.length; r++) {
    for (let c = 0; c < initialOutcomeGrid[r].length; c++) {
      if (
        ([1, 3].includes(r) && r === rowIndex) ||
        ([1, 3].includes(c) && c === columnIndex)
      ) {
        newGuessOutcomeGrid[r][c] = initialOutcomeGrid[r][c];
      } else {
        newGuessOutcomeGrid[r][c] = LetterOutcome.NO_GUESS;
      }
    }
  }

  return newGuessOutcomeGrid;
}

function processNoGuesses(
  guessGrid: Grid,
  answerGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
) {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (r === 1 || r === 3 || c === 1 || c === 3) {
        guessOutcomeGrid[r][c] = LetterOutcome.NO_GUESS;
      }
    }
  }
}

function processGreens(
  guessGrid: Grid,
  answerGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
) {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      const guessedLetter = guessGrid[r][c];
      const answerLetter = answerGrid[r][c];
      if (guessedLetter && guessedLetter === answerLetter) {
        guessOutcomeGrid[r][c] = LetterOutcome.CORRECT;
      }
    }
  }
}

function processYellows(
  guessGrid: Grid,
  answerGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid
) {
  for (let r = 0; r < guessGrid.length; r++) {
    for (let c = 0; c < guessGrid[r].length; c++) {
      if (
        (r === 1 || r === 3 || c === 1 || c === 3) &&
        guessOutcomeGrid[r][c] !== LetterOutcome.CORRECT &&
        guessGrid[r][c]
      ) {
        const guessedLetter = guessGrid[r][c];
        const answerWords = getGridWordsForIndexes(answerGrid, r, c);

        const answerLetterCount = countLetterInstancesInWords(
          guessedLetter,
          answerWords
        );

        const correctOutcomeCount = countCorrectLetterGuessesInTwoWords(
          guessedLetter,
          answerGrid,
          guessOutcomeGrid,
          r,
          c
        );

        if (answerLetterCount > correctOutcomeCount) {
          guessOutcomeGrid[r][c] = LetterOutcome.LETTER_IN_WORD;
        }
      }
    }
  }
}

function countCorrectLetterGuessesInTwoWords(
  letter: string | null,
  answerGrid: Grid,
  guessOutcomeGrid: GuessOutcomeGrid,
  intersectionRowIndex: number,
  intersectionColumnIndex: number
): number {
  let correctLetterCount = 0;

  for (let r = 0; r < answerGrid.length; r++) {
    for (let c = 0; c < answerGrid[r].length; c++) {
      if (
        ([1, 3].includes(r) && r === intersectionRowIndex) ||
        ([1, 3].includes(c) && c === intersectionColumnIndex)
      ) {
        if (r !== intersectionRowIndex || c !== intersectionColumnIndex) {
          if (
            letter === answerGrid[r][c] &&
            guessOutcomeGrid[r][c] === LetterOutcome.CORRECT
          ) {
            correctLetterCount++;
          }
        }
      }
    }
  }

  if (
    letter === answerGrid[intersectionRowIndex][intersectionColumnIndex] &&
    guessOutcomeGrid[intersectionRowIndex][intersectionColumnIndex] ===
      LetterOutcome.CORRECT
  ) {
    correctLetterCount++;
  }

  return correctLetterCount;
}

function countLetterInstancesInWords(
  letter: string | null,
  intersectingWords: Array<IntersectingWord>
) {
  const letters = [];

  for (const intersectingWord of intersectingWords) {
    letters.push(...getNonIntersectingLetters(intersectingWord));
  }
  const firstIntersectingWord = intersectingWords[0];
  letters.push(
    firstIntersectingWord.word[firstIntersectingWord.intersectionIndex]
  );

  let letterCount = 0;

  for (let i = 0; i < letters.length; i++) {
    if (letters[i] === letter) {
      letterCount++;
    }
  }

  return letterCount;
}

function getNonIntersectingLetters(
  intersectingWord: IntersectingWord
): Array<string | null> {
  const letters = [];

  for (let i = 0; i < intersectingWord.word.length; i++) {
    if (i !== intersectingWord.intersectionIndex) {
      letters.push(intersectingWord.word[i]);
    }
  }

  return letters;
}

type IntersectingWord = {
  word: Array<string | null>;
  intersectionIndex: number;
};

function getGridWordsForIndexes(
  grid: Grid,
  rowIndex: number,
  columnIndex: number
): Array<IntersectingWord> {
  const answerWords = [];

  if (rowIndex === 1) {
    answerWords.push({
      word: grid[1],
      intersectionIndex: columnIndex,
    });
  }

  if (rowIndex === 3) {
    answerWords.push({
      word: grid[3],
      intersectionIndex: columnIndex,
    });
  }

  if (columnIndex === 1) {
    answerWords.push({
      word: grid.map((row) => row[1]),
      intersectionIndex: rowIndex,
    });
  }

  if (columnIndex === 3) {
    answerWords.push({
      word: grid.map((row) => row[3]),
      intersectionIndex: rowIndex,
    });
  }

  return answerWords;
}
