import React, { useEffect, useState } from 'react';
import './App.css';
import { GRID_SIZE } from "./constants/grid";
import Game from "./Game";
import {clearStateFromStorage, getStateFromStorage, saveStateToStorage} from "./helpers/localStorage";
import {makeNullGridOfSize} from "./utils/makeNullGridOfSize";
import {
  addLetterToGrid,
  fillGridColumnWithWord,
  fillGridRowWithWord
} from "./utils/grid";
import { getGuessOutcomeGrid } from "./helpers/evaluateGuess";
import {LetterOutcome} from "./types/WordGuessOutcome";
import Div100vh from 'react-div-100vh'
import getPuzzle from "./helpers/getPuzzle";
import {GameState, HistoricalGuess, LetterOutcomeHash} from "./types/GameState";
import { isEqual } from 'lodash';
import Loader from "./Loader";

const answerGrid = makeNullGridOfSize(GRID_SIZE);
const initialGuessGrid = makeNullGridOfSize(GRID_SIZE);

const { wordQuartet, revealedCoordinates } = getPuzzle();

// For testing a fixed board
// const revealedCoordinates = [[1,2], [3,3], [1,4], [3,0]];
// const revealedCoordinates = getRandomFourFromArray(gridLetterPositions);

const revealed1Coordinates = revealedCoordinates[0];
const revealed2Coordinates = revealedCoordinates[1];
const revealed3Coordinates = revealedCoordinates[2];
const revealed4Coordinates = revealedCoordinates[3];

console.log('wordQuartet | App: L53');
console.log(wordQuartet);

// For testing a fixed board
// const column2Word = 'ARSON'; // Column 2
// fillGridColumnWithWord(answerGrid,1, column2Word);
// const row2Word = 'TRUER';  // Row 2
// fillGridRowWithWord(answerGrid, 1, row2Word);
// const column4Word = 'BEAST'; // Column 4
// fillGridColumnWithWord(answerGrid,3, column4Word);
// const row4Word = 'LOOSE';  // Row 4
// fillGridRowWithWord(answerGrid,3, row4Word);

const column2Word = wordQuartet[0]; // Column 2
fillGridColumnWithWord(answerGrid,1, column2Word);
const row2Word = wordQuartet[1]; // Row 2
fillGridRowWithWord(answerGrid, 1, row2Word);
const column4Word = wordQuartet[2]; // Column 4
fillGridColumnWithWord(answerGrid,3, column4Word);
const row4Word = wordQuartet[3]; // Row 4
fillGridRowWithWord(answerGrid,3, row4Word);

const revealed1 = answerGrid[revealed1Coordinates[0]][revealed1Coordinates[1]]
const revealed2 = answerGrid[revealed2Coordinates[0]][revealed2Coordinates[1]]
const revealed3 = answerGrid[revealed3Coordinates[0]][revealed3Coordinates[1]]
const revealed4 = answerGrid[revealed4Coordinates[0]][revealed4Coordinates[1]]

// Fill in the four starter letters on guessGrid
addLetterToGrid(initialGuessGrid, revealed1, revealed1Coordinates[0], revealed1Coordinates[1]);
addLetterToGrid(initialGuessGrid, revealed2, revealed2Coordinates[0], revealed2Coordinates[1]);
addLetterToGrid(initialGuessGrid, revealed3, revealed3Coordinates[0], revealed3Coordinates[1]);
addLetterToGrid(initialGuessGrid, revealed4, revealed4Coordinates[0], revealed4Coordinates[1]);

export const initialGuessOutcomeGrid = getGuessOutcomeGrid(initialGuessGrid, answerGrid);

export const initialRemainingLettersOutcome: LetterOutcomeHash = {
  'A': LetterOutcome.NO_GUESS,
  'B': LetterOutcome.NO_GUESS,
  'C': LetterOutcome.NO_GUESS,
  'D': LetterOutcome.NO_GUESS,
  'E': LetterOutcome.NO_GUESS,
  'F': LetterOutcome.NO_GUESS,
  'G': LetterOutcome.NO_GUESS,
  'H': LetterOutcome.NO_GUESS,
  'I': LetterOutcome.NO_GUESS,
  'J': LetterOutcome.NO_GUESS,
  'K': LetterOutcome.NO_GUESS,
  'L': LetterOutcome.NO_GUESS,
  'M': LetterOutcome.NO_GUESS,
  'N': LetterOutcome.NO_GUESS,
  'O': LetterOutcome.NO_GUESS,
  'P': LetterOutcome.NO_GUESS,
  'Q': LetterOutcome.NO_GUESS,
  'R': LetterOutcome.NO_GUESS,
  'S': LetterOutcome.NO_GUESS,
  'T': LetterOutcome.NO_GUESS,
  'U': LetterOutcome.NO_GUESS,
  'V': LetterOutcome.NO_GUESS,
  'W': LetterOutcome.NO_GUESS,
  'X': LetterOutcome.NO_GUESS,
  'Y': LetterOutcome.NO_GUESS,
  'Z': LetterOutcome.NO_GUESS,
}

initialRemainingLettersOutcome[revealed1] = LetterOutcome.CORRECT;
initialRemainingLettersOutcome[revealed2] = LetterOutcome.CORRECT;
initialRemainingLettersOutcome[revealed3] = LetterOutcome.CORRECT;
initialRemainingLettersOutcome[revealed4] = LetterOutcome.CORRECT;

const initialGuessHistory: Array<HistoricalGuess> = [];

let initialFocusedCell;
if (!initialGuessGrid[1][0]) {
  initialFocusedCell = { row: 1, column: 0 };
} else if (!initialGuessGrid[1][1]) {
  initialFocusedCell = { row: 1, column: 1 };
} else if (!initialGuessGrid[1][2]) {
  initialFocusedCell = { row: 1, column: 2 };
} else if (!initialGuessGrid[1][3]) {
  initialFocusedCell = { row: 1, column: 3 };
} else {
  initialFocusedCell = { row: 1, column: 4 };
}

const DEFAULT_GAME_STATE: GameState = {
  wordQuartet,
  answerGrid,
  guessGrid: initialGuessGrid,
  guessOutcomeGrid: initialGuessOutcomeGrid,
  guessHistory: initialGuessHistory,
  focusedCell: initialFocusedCell,
  remainingLettersOutcomes: initialRemainingLettersOutcome,
  outcome: 'pending',
}

function App() {
  const [gameState, setGameState] = useState<GameState | null>(DEFAULT_GAME_STATE);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storageGameState = getStateFromStorage();

    if (!storageGameState || !isEqual(storageGameState.wordQuartet, wordQuartet)) {
      // Clear the game state saved in local storage if it's a new day's puzzle
      clearStateFromStorage();
      setGameState(DEFAULT_GAME_STATE);
    } else {
      setGameState(storageGameState);
    }
    setIsLoading(false);
  }, [])

  function saveGameState(gameState: GameState) {
    setGameState(gameState);
    saveStateToStorage(gameState)
  }

  if (!gameState) {
    return <div className="App" >
      LOADING...
    </div>
  }

  return (
    <Div100vh>
      { isLoading ?
        <Loader /> :
        <Game
          wordQuartet={wordQuartet}
          gameState={gameState}
          saveGameState={saveGameState}
        />}
    </Div100vh>
  )
}

export default App;
