import {GameState} from "../types/GameState";

const STORAGE_KEY = 'hash-word-storage-key-810'

export function getStateFromStorage(): GameState | null {
  const stringifiedValue = localStorage.getItem(STORAGE_KEY);
  if (!stringifiedValue || stringifiedValue === 'undefined') {
    return null;
  } else {
    return JSON.parse(stringifiedValue) as GameState;
  }
}

export function saveStateToStorage(gameState: GameState) {
  const stringifiedValue = JSON.stringify(gameState);
  localStorage.setItem(STORAGE_KEY, stringifiedValue);
}

export function clearStateFromStorage() {
  localStorage.removeItem(STORAGE_KEY);
}
