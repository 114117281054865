import Cell from "../types/Cell";
import {DOWN_ARROW_CODE, LEFT_ARROW_CODE, RIGHT_ARROW_CODE, UP_ARROW_CODE} from "../Keyboard";

export default function getNextFocusedCellFromArrowKeyCode(focusedCell: Cell | null, arrowKeyCode: number): Cell | null {
  if (!focusedCell) {
    return null
  }

  let nextFocusedCell;
  if (arrowKeyCode === LEFT_ARROW_CODE) {
    nextFocusedCell = {
      ...focusedCell,
      column: focusedCell.column - 1,
    }
  } else if (arrowKeyCode === UP_ARROW_CODE) {
    nextFocusedCell = {
      ...focusedCell,
      row: focusedCell.row - 1,
    }
  } else if (arrowKeyCode === RIGHT_ARROW_CODE) {
    nextFocusedCell = {
      ...focusedCell,
      column: focusedCell.column + 1,
    }
  } else if (arrowKeyCode === DOWN_ARROW_CODE) {
    nextFocusedCell = {
      ...focusedCell,
      row: focusedCell.row + 1,
    }
  } else {
    return null;
  }

  if (
    ([0,2,4].includes(nextFocusedCell.column) && [0,2,4].includes(nextFocusedCell.row)) ||
    nextFocusedCell.row > 4 ||
    nextFocusedCell.column > 4 ||
    nextFocusedCell.row < 0 ||
    nextFocusedCell.column < 0
  ) {
    return null;
  } else {
    return nextFocusedCell
  }

}
