import Cell from "../types/Cell";
import {Grid} from "../types/Grid";

/*
High-level Summary:
- If it's the last cell of the row / column, do nothing
- If it's NOT at an intersection, move down the row / column to the next free cell (if there are none free, do nothing)
- If it IS at an intersection, check the previous cell to see whether you're on a row or column (there are still some edge cases here to tackle)
 */

export default function getNextFocusedCell(focusedCell: Cell, prevFocusedCell: Cell | null, guessGrid: Grid): Cell {
  if (focusedCell.row === 4 || focusedCell.column === 4) {
    return focusedCell;
  }

  // If not at an intersection, move down the row/column
  if (!([1, 3].includes(focusedCell.row) && [1, 3].includes(focusedCell.column))) {
    // On a row
    if ([1, 3].includes(focusedCell.row)) {
      return getNextFreeCellInRow(focusedCell, guessGrid) || focusedCell;
    // On a column
    } else {
      return getNextFreeCellInColumn(focusedCell, guessGrid) || focusedCell;
    }
  }

  // If at an intersection, use the previous cell to tell whether to move down the row or the column
  if (!prevFocusedCell) {
    // TODO: try to make a decision about which way to go (eg. if the word is finished in one way, go the other)
    return focusedCell
  // On a row
  } else if (prevFocusedCell.row === focusedCell.row) {
    return getNextFreeCellInRow(focusedCell, guessGrid) || focusedCell;
  // On a column
  } else if (prevFocusedCell.column === focusedCell.column) {
    return getNextFreeCellInColumn(focusedCell, guessGrid) || focusedCell;
  // Unclear whether row or column
  } else {
    // TODO: try to make a decision about which way to go (eg. if the word is finished in one way, go the other)
    return focusedCell
  }
}

function getNextFreeCellInRow(focusedCell: Cell, guessGrid: Grid): Cell | null {
  const startingColumnIndex = focusedCell.column + 1;
  const rowIndex = focusedCell.row;
  for (let i = startingColumnIndex; i <= 4; i++) {
    if (guessGrid[rowIndex][i] === null) {
      return { row: rowIndex, column: i };
    }
  }

  return null;
}

function getNextFreeCellInColumn(focusedCell: Cell, guessGrid: Grid): Cell | null {
  const startingRowIndex = focusedCell.row + 1;
  const columnIndex = focusedCell.column;
  for (let i = startingRowIndex; i <= 4; i++) {
    if (guessGrid[i][columnIndex] === null) {
      return { row: i, column: columnIndex };
    }
  }

  return null;
}
